//--------------------------------------------------------------
//                    Variables
//--------------------------------------------------------------
//Backgrounds
$headerBg: #363441;
$contentBg: #fff;
$footerBg: #363441;
$baseBg: $footerBg;

// Fonts
$baseFontFamily: 'Roboto', sans-serif;
$baseFontSize: 16px;
$baseLineHeight: 23px;
$baseFont: 400 #{$baseFontSize}/#{$baseLineHeight} $baseFontFamily;

// Colors
$baseColor: #777;
$primary: #3D5F8C;
$primary2: #4893f5;
$primaryLight: #5C7EAB;
$primaryDark: darken(desaturate(adjust_hue($primary, 1.7172264355362756), 11.43573852737201%), 19.01960784313726%);
$secondary: #0264AF;
$secondaryDark: darken(desaturate(adjust_hue($secondary, -26.258881680568436), 41.68432652369248%), 12.352941176470587%);
$secondaryDarkest: darken(desaturate(adjust_hue($secondary, -26.22351474864979), 33.05977710233032%), 21.96078431372549%);
$tetriary: #fff;
$yellow: #ffd63a;
$grey: #ddd;

$textSecondary: #fff;
$textPrimary: #363441;
$textTetriary: #938db2;
$textTetriaryDark: darken(desaturate(adjust_hue($textTetriary, -0.4989604989604288), 7.764584891548235%), 18.627450980392155%);

$borderColor: #dedde0;
$borderColor1: #67637d;
$stockBg: #fafafa;
$stockBgHover: $primaryLight;


//Olark Chat Background
$olark-heading-bg: $textTetriary;

// Border-radius
$baseBorderRadius: 0;

//Box Shadow
$baseBoxShadow: 1px 1px 8px rgba(#000,.15);