/*========================================================
                 Tab Products Module
=========================================================*/
.module_tab {
	clear: both;
	margin-top: 30px;
	.nav-tabs {
		padding: 0;
		border: 0;
		margin-bottom: 30px;
		text-align: center;
		> li {
			float: none;
			display: inline-block;
			> a, > a:focus {
				border: 0;
				text-transform: uppercase;
				font: 700 16px/40px $baseFontFamily;
				color: $textPrimary;
			}
			>a{
				padding: 0;
				letter-spacing: .7px;
      			&:after {
      			  position: absolute;
      			  top: 103%;
      			  left: 0;
      			  width: 100%;
      			  height: 1px;
      			  background: $primary;
      			  content: '';
      			  opacity: 0;
      			  -moz-transition: height 0.3s, opacity 0.3s, -moz-transform 0.3s;
      			  -webkit-transition: height 0.3s, opacity 0.3s, -webkit-transform 0.3s;
      			  transition: height 0.3s, opacity 0.3s, transform 0.3s;
      			  @include transform (translateY(-8px));
      			}				
			}
			&.active > a, > a:hover {
				color: $primary;
				border: 0;
				background: none;
				&:after {
        			height: 4px;
        			opacity: 1;
        			@include transform (translateY(0px));
      			}
			}
			& + li {
				margin-left: 35px;
			}
		}
	}
}

.tab-content {
	margin-top: 0;
	padding: 0;
	.box{
		margin-bottom: 0;
	}
}

/*========================================================
           Tab Products Module Responsive Layout
=========================================================*/