//--------------------------------------------------------------
//                    Owl Carousel Module
//--------------------------------------------------------------
.owl-carousel {
	position: relative;
	margin: 0 -15px;
	width: auto;
	.owl-item{
		padding: 20px 15px;
		width: auto;
	}
}

// Navigation

.owl-carousel {
	.owl-nav {
		div {
			opacity: 0;
			color: #c9c9c9;
			text-shadow: 1px 1px 0 rgba(#fff,0.3);
			transition: all .3s ease;
			font-size: 40px;
			position: absolute;
			top: 50%;
			@include transform(translateY(-50%));
			&:hover{
				color: $textPrimary;
			}
		}
		.owl-prev {
			left: -50px;
		}
		.owl-next {
			right: -50px;
		}
	}
	&:hover{
		.owl-nav {
			div {
				opacity: 0.7;
			}
			.owl-prev{
				left: 10px;
			}
			.owl-next{
				right: 10px;
			}
		}
	}
	@media (max-width: 767px) {
		.owl-nav {
			div{
				opacity: .7;
			}
			.owl-prev{
				left: 10px;
			}
			.owl-next{
				right: 10px;
			}
		}
	}
}

.testimonials.owl-carousel{
	.owl-nav{
		margin-top: 30px;
		div{
			opacity: 1;
			position: static;
			display: inline-block;
			margin: 0 5px;
		}
	}
}

// Pagination

.owl-pagination {
	text-align: center;
	top: 40px;
	position: relative;
}
.owl-controls .owl-page {
	display: inline-block;
	margin: 0px 6px;
	span {
		display: block;
		width: 11px;
		height: 11px;
		background: rgba(0, 0, 0, 0.5);
		border-radius: 20px;
		box-shadow: inset 0 0 3px rgba(0,0,0,0.3);
	}
	&.active span{
		background: rgba(0, 0, 0, 0.9);
	}
}
