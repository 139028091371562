/*==================   RD Mobile Menu    =====================*/
$dark-theme: (
panel-background-color: $stockBg,
panel-text-color: #FFF,
menu-background-color: $stockBg,
menu-item-color: $textTetriary,
menu-item-hover-color: #FFF,
menu-item-hover-background: $primary,
menu-item-active-color: #fff,
menu-item-active-background: $primaryDark
);

$light-theme: (
panel-background-color: #FFF,
panel-text-color: #fff,
menu-background-color: #FFF,
menu-item-color: #666,
menu-item-hover-color: #FFF,
menu-item-hover-background: $primary,
menu-item-active-color: darken(desaturate(adjust_hue($primary, 2.501734687440859), 32.613229449494355%), 36.07843137254902%),
menu-item-active-background: #f5f5f5
);

$rd-theme: $dark-theme;
$rd-menu-toggle-preset: "button-lines-arrow-2";

$rd-mobilepanel-height: 56px;
$rd-button-size: 48px;
$rd-icon-size: 24px;
$rd-menu-width: 240px;

.rd-mobilemenu {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	z-index: 10000;
	text-align: left;
	@include transition(.3s all ease);

	&.active {
		right: 0;
	}
}

.rd-mobilemenu_ul {
	position: fixed;
	top: -$rd-mobilepanel-height;
	left: 0;
	bottom: -30px;
	width: $rd-menu-width;
	padding: 112px 0 (20px + $rd-mobilepanel-height);
	color: #3D5F8C;
	background: #efefef;
	font-size: 14px;
	line-height: 20px;
	overflow: auto;
	@include box-shadow(5px 0 5px 0 rgba(0, 0, 0, .1));
	@include transform(translateX(-$rd-menu-width));
	@include transition(0.3s ease);
	@include transition(0.3s cubic-bezier(.55, 0, .1, 1));

	.rd-mobilemenu.active & {
		@include transform(translateX(0));
	}


	a {
		display: block;
		padding: 14px 45px 16px 25px;
		i{
			margin-right: 7px;
			position: relative;
			top: 2px;
			color: #fafafa;
		}

		&.active {
			background: map-get($rd-theme, menu-item-active-background);
			color: map-get($rd-theme, menu-item-active-color);
		}

		&:hover {
			background: map-get($rd-theme, menu-item-hover-background);
			color: map-get($rd-theme, menu-item-hover-color);
		}

		.rd-submenu-toggle {
			position: absolute;
			top: 50%;
			right: 11px;
			margin-top: -17.5px;
			width: 32px;
			height: 32px;
			font: 400 10px $fa;
			line-height: 32px;
			text-align: center;
			-webkit-border-radius: 50%;
			-moz-border-radius: 50%;
			border-radius: 50%;
			@include transition(.5s all ease);
			z-index: 1;
			background: rgba(darken(desaturate(adjust_hue($primary, 2.501734687440859), 32.613229449494355%), 36.07843137254902%), .1);
			&:after {
				content: '\f078';
			}

			&:hover{
				background: map-get($rd-theme, menu-background-color);
				color: map-get($rd-theme, menu-item-color);
			}
		}

		&.rd-with-ul {
			position: relative;

			&.active {
				.rd-submenu-toggle {

				}
			}
		}
	}

	ul {
		list-style-type: none;
		padding-left: 0;
		a {
			padding-left: 50px;
		}
	}

	ul ul a {
		padding-left: 0px;
		text-align: center;
		span{
			display: block;
		}
	}

	&:after {
		content: '';
		display: block;
		height: 20px;
	}
}

.rd-mobilepanel {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	height: $rd-mobilepanel-height;
	background: #6E9ECF;
	color: map-get($rd-theme, panel-text-color);
	z-index: 10010;
}

.rd-mobilepanel_title {
	position: fixed;
	top: 4px;
	left: $rd-mobilepanel-height - 4;
	right: $rd-mobilepanel-height+56px;
	color: map-get($rd-theme, panel-text-color);
	font-size: $rd-icon-size;
	line-height: $rd-button-size;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	margin-top: 0;
}

//===========   Mobilepanel Toggle Presets   ==============

// Hamburger
@mixin button-lines() {
	span {
		position: relative;
		display: block;
		margin: auto;
		@include transition(all .3s ease);

		&:after,
			&:before {
			content: "";
			position: absolute;
			left: 0;
			top: (round($rd-icon-size / 6)) * -2;
			@include transition(all .3s ease);
		}

		&:after {
			top: (round($rd-icon-size / 6)) * 2;
		}

		&:after,
			&:before,
			& {
			width: $rd-icon-size;
			height: round($rd-icon-size / 6);
			background-color: map-get($rd-theme, panel-text-color);
			backface-visibility: hidden;
			@include border-radius(2px);
		}
	}
}

// Hamburger to Cross
@mixin button-lines__cross-1() {
	&.active span {
		background: transparent;

		&:before, &:after {
			@include transform-origin(50% 50%);
			top: 0;
		}

		&:before {
			@include transform(rotate(45deg));
		}
		&:after {
			@include transform(rotate(-45deg));
		}
	}
}

@mixin button-lines__cross-2() {
	span {
		&:before, &:after {
			-webkit-transition-duration: 0.3s, 0.3s;
			transition-duration: 0.3s, 0.3s;
			-webkit-transition-delay: 0.3s, 0s;
			transition-delay: 0.3s, 0s;
			-webkit-transition-property: top, -webkit-transform;
			transition-property: top, transform;
		}
	}

	&.active span {
		transition: background .3s 0s ease;
		background: transparent;

		&:before, &:after {
			top: 0;
			-webkit-transition-delay: 0s, 0.3s;
			transition-delay: 0s, 0.3s;
		}
		&:before {
			-webkit-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
			transform: rotate(45deg);
		}
		&:after {
			-webkit-transform: rotate(-45deg);
			-ms-transform: rotate(-45deg);
			transform: rotate(-45deg);
		}
	}
}

@mixin button-lines__cross-3() {
	span {
		&:before, &:after {
			-webkit-transition-duration: 0.3s, 0.3s;
			transition-duration: 0.3s, 0.3s;
			-webkit-transition-delay: 0.3s, 0s;
			transition-delay: 0.3s, 0s;
			-webkit-transition-property: top, -webkit-transform;
			transition-property: top, transform;
		}
	}

	&.active span {

		transition: background .3s 0s ease;
		background: transparent;

		&:before, &:after {
			top: 0;
			-webkit-transition-delay: 0s, 0.3s;
			transition-delay: 0s, 0.3s;
		}
		&:before {
			-webkit-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
			transform: rotate(45deg);
		}
		&:after {
			-webkit-transform: rotate(-45deg);
			-ms-transform: rotate(-45deg);
			transform: rotate(-45deg);
		}
	}
}

// Hamburger to Arrow
@mixin button-lines__arrow-1() {
	span {
		&:before,
			&:after {
			@include transform-origin($rd-icon-size/14 center);
		}
	}

	&.active span {
		&:before,
			&:after {
			top: 0;
			width: $rd-icon-size/1.6;
		}

		&:before {
			@include transform(rotate(-40deg));
		}
		&:after {
			@include transform(rotate(40deg));
		}
	}
}

// Hamburger to Arrow (Material Design)
@mixin button-lines__arrow-2() {
	span {
		@include transform(rotate(180deg));
		&:before,
			&:after {
			@include transform-origin($rd-icon-size/14 center);
			@include transform-origin($rd-icon-size/14 center);
		}
	}

	&.active span {
		@include transform(rotate(360deg));

		&:before,
			&:after {
			top: 0;
			width: $rd-icon-size/1.6;
		}

		&:before {
			-webkit-transform: rotate3d(0, 0, 1, -40deg);
			transform: rotate3d(0, 0, 1, -40deg);
		}
		&:after {
			-webkit-transform: rotate3d(0, 0, 1, 40deg);
			transform: rotate3d(0, 0, 1, 40deg);
		}
	}
}

// Hamburger to Minus
@mixin button-lines__minus-1() {
	span {
		&:before,
			&:after {
			@include transition(all .3s ease);
		}
	}

	&.active span {
		&:before,
			&:after {
			top: 0;
		}
	}
}

//===============   Mobilepanel Toggle   =================
.rd-mobilepanel_toggle {
	@include reset-button();
	position: fixed;
	top: 4px;
	left: 4px;
	width: $rd-button-size;
	height: $rd-button-size;

	@if ($rd-menu-toggle-preset == button-lines-cross-1) {
		@include button-lines();
		@include button-lines__cross-1();
	}

	@if ($rd-menu-toggle-preset == button-lines-cross-2) {
		@include button-lines();
		@include button-lines__cross-2();
	}

	@if ($rd-menu-toggle-preset == button-lines-cross-3) {
		@include button-lines();
		@include button-lines__cross-3();
	}

	@if ($rd-menu-toggle-preset == button-lines-arrow-1) {
		@include button-lines();
		@include button-lines__arrow-1();
	}

	@if ($rd-menu-toggle-preset == button-lines-arrow-2) {
		@include button-lines();
		@include button-lines__arrow-2();
	}

	@if ($rd-menu-toggle-preset == button-lines-minus-1) {
		@include button-lines();
		@include button-lines__minus-1();
	}
}

.rd-mobilepanel_toggle{ 
	&:hover{
		span:after,
			span:before, 
			span {
			background: #fff;
		}
	}
}

@media (max-width: 767px) {
	body {
		padding-top: $rd-mobilepanel-height;
	}

	.rd-mobilemenu, .rd-mobilepanel {
		display: block;
	}
	.rd-mobilepanel {
		.search_block {
			padding-top: 10px;
			width: calc(100% - 220px);
			display: block;
			margin-left: 54px;
			.input-search {
				background: #fff;
			}
		}
	}
	.cart,.product-compare-header {
		>button{
			i{
				top: -3px;
				color: #fff;
			}
			&:hover i{
				color: $tetriary;
			}
		}
		>button.active{
			i{
				color: $tetriary;
			}
		}
		z-index: 10001;
	}
	.toggle_cont{
		padding: 0;
		left: auto;
		right: 0;
		top: 100%;
		i{
			display: none;
		}
		.box-language &,
		.box-currency &{
			left: 0;
			top: 120%;
		}
		p{
			color: $textSecondary;
		}
	}
	header .nav {
		right: 0px;
		top: 2px;
		z-index: 10001;
		.toggle_cont{
			left: auto;
			right: 0;
			margin-top: 0;
			top: 54px;
		}
		> .toggle{
			line-height: 53px;
			right: 40px;
			position: absolute;
		}
	}
	.cart>button, .nav > .toggle{
		color: $textSecondary;
		font-size: 28px;
		&:hover{
			color: $tetriary;
		}
	}
}

@media (max-width: 400px) {
	.rd-mobilepanel_title {
		font-size: 18px;
		text-align: left;
		padding-left: 0;
	}
}