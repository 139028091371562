/*========================================================
                      HTML boxes
=========================================================*/

.content-block{
	position: relative;
	padding: 47px 0 86px;
	margin: 0 15px;
	border-bottom: 1px solid $borderColor1;
	text-align: center;
	.cols{
		h1,h2,h3,h4,h5,h6{
			color: #fff;
			position: relative;
		}
		h4{
			font-size: 22px;
			line-height: 33px;
			margin-bottom: 15px;
			text-transform: uppercase;
			letter-spacing: .7px;
			font-weight: 700;
		}
		p {
			color: $textTetriary;
			font-size: 16px;
			line-height: 24px;
			padding: 0 11px;
		}
		.circle-bg-1,
		.circle-bg-2,
		.circle-bg-3{
			@include border-radius(50%);
			height: 101px;
			width: 101px;
			line-height: 101px;
			display: inline-block;
			position: relative;
			text-align: center;
			margin: 30px 0 20px;
		}
		.circle-bg-1{
			background-color: $secondary;
  			background-image: linear-gradient(to top, $secondary, $tetriary);
		}		
		.circle-bg-2{
			background-color: $primary;
  			background-image: linear-gradient(to top, $primary, lighten(saturate(adjust_hue($primaryLight, -13.547497043752458), 11.340206185566998%), 3.3333333333333357%));
		}
		.circle-bg-3{
			background-color: $secondary;
  			background-image: linear-gradient(to top, $secondaryDarkest, $secondary);
		}		
		i{
			display: inline-block;
    		font-size: 44px;
    		line-height: 100px;
    		left: 0;
    		right: 0;
    		color: #fff;
    		position: absolute;
		}
		.btn{
			background: transparent;
			margin-top: 25px;
			padding-left: 30px;
			padding-right: 30px;
			&:before{
				border: 2px solid #fff;
				position: absolute;
				left: 0;
				top: 0;
				right: 0;
				bottom: 0;
				border-radius: 25px;
				display: inline-block;
				content: "";
			}
			&:after {
			  	background-image: linear-gradient(to top, darken(desaturate(adjust_hue($secondary, 24.315973571706195), 25.671609714162926%), 1.9607843137254832%), $tetriary);
			}			
		}
	}
}

/*========================================================
               Responsive HTML boxes
=========================================================*/

@media (max-width: 991px) {
	.content-block{
		padding-bottom: 60px; 
	}
}

@media (max-width: 767px) {


}