//--------------------------------------------------------------
//                    Search Form
//--------------------------------------------------------------
// input variables
$form-bg: transparent;
$form-width: 100%;
$form-height: 54px;
$form-border: 1px solid $textTetriaryDark;
$form-color: $textTetriary;

// button variables
$form-submit-color: $textSecondary;
$form-submit-hover: $primary;

.search{
	position: relative;
	width: 100%;
	z-index: 100;
	input[type="text"] {
		color: $form-color;
		display: block;
		margin: 0;
		font-size: 14px;
		line-height: 24px;
		width: $form-width;
		height: 38px;
		padding: (($form-height - 32px) / 2) 50px (($form-height - 32px) / 2) 20px;
		background: $form-bg;
		border: $form-border;
		@include border-radius(27px);
		@include box-sizing(border-box);
		&:focus{
			outline: none;
		}
		&::-webkit-input-placeholder{
			color: $form-color;
			opacity: 1;
		}
		&:-moz-placeholder{
			color: $form-color;
			opacity: 1;
		}
		&::-moz-placeholder{
			color: $form-color;
			opacity: 1;
		}
		&:-ms-input-placeholder  {
			color: $form-color;
			opacity: 1;
		}
	}

	button, button:focus, button:active {
		@include reset-button;
		@include transition(.3s all ease);
		position: absolute;
		right: 12px;
		top: 0;
		color: $primary;
		font: 400 15px/37px $fa;
	}

	button:hover, button:focus:hover, button:active:hover {
		color: $form-submit-hover;
	}

	.autosearch {
		li {
			margin-bottom: 10px;
			min-height: 75px;		
			width: 100%;
			padding: 10px 0;
			display: inline-block;
			&:hover {	
				background: #eee;	
			}
			a{
				padding: 0 10px;
				.as-title {
					white-space: normal;
				}
				.as-image {
					img{
						width: 75px;
					}			
				}
				.as-price {
					font-size: 20px;
				}
			}
			&.showall {
				text-align: center;
				min-height: 1px;
				padding: 0;
				&:hover {
					background: #fff;
				}
				#showall:hover {
					color: #fff;
					&:after {
						content: inherit;
					}
				}
			}
		}
	}
}



//--------------------------------------------------------------
//                    Responsive Search Form Layout
//--------------------------------------------------------------

@media (max-width: 1199px) {
	.search{
		width: 260px;
	}
}
@media (max-width: 991px) {
	.search{
		width: 138px;
	}
}
@media (max-width: 767px) {
	.search{
		width: 52%;
		z-index: auto;
	}
}
@media (max-width: 640px) {
	.search{
		width: 70%;
	}
}
@media (max-width: 480px) {
	.search{
		width: 100%;
	}	
}

@media (min-width: 768px) and (max-width: 991px) {
	#livesearch_search_results li img{
		display: none!important;
	}
}
