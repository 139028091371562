/*========================================================
                      Menu styles
=========================================================*/
@import "_floated-menu.scss";

.navigation{
	position: fixed;
	top: 97px;
	left: 50%;
	@include transform(translateX(-50%));
	z-index: 9999;
}

.megamenu{
	position: relative;
	padding: 0;
	background: transparent;
	margin: 0;
	z-index: 20;
}

.sf-menu {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
}

/*================ Menu  ===============*/
.sf-menu{
	display: block;
	li {
		position: relative;
		&.sfHover > a, > a:hover, >a.active{
			color: $primary;
			text-shadow: none;
			&:last-child {
				color: #ff3535;
			}
			&:after {
				height: 5px;
				opacity: 1;
				@include transform (translateY(0px));
			}
		}
		&.sf-with-mega{
			position: static;
		}
	}
	>li{
		text-align: center;
		float: left;
		display: table;
		height: 60px;
		padding: 0;    
		margin: 5px 32px;
		&:last-child{
			margin-right: 0;	
			a {
				color: #ff3535;
			}
		}
		> a {
			line-height: 60px;
			display: table-cell;
			color: $primary;
			.common-home &{
				color: #fff;

			}
			font-size: 16px;
			line-height: 70px;
			letter-spacing: 1px;
			font-weight: 500;
			position: relative;
			text-transform: uppercase;
			vertical-align: middle;
			&:after {
				position: absolute;
				top: 60px;
				@media (max-width: 1199px) {
					top: 50px;
				}
				@media (max-width: 991px) {
					top: 45px;
				}
				left: 0;
				width: 100%;
				height: 1px;
				background: $primary;
				content: '';
				opacity: 0;
				-moz-transition: height 0.3s, opacity 0.3s, -moz-transform 0.3s;
				-webkit-transition: height 0.3s, opacity 0.3s, -webkit-transform 0.3s;
				transition: height 0.3s, opacity 0.3s, transform 0.3s;
				@include transform (translateY(-8px));
			}
			i{
				font-size: 27px;
				line-height: 24px;
				margin-right: 10px;
				vertical-align: middle;
				display: inline-block;
			}
		}
		&+li{
			margin-left: 0;
		}
	}
}

.common-home {
	.sf-menu li:last-child a {
		color: #0264AF;
	}
}

/*================ Sub-menu lvl1  ===============*/
.sf-menu .simple_menu {
	list-style-type: none;
	display: none;
	position: absolute;
	z-index: 109;
	left: 0;
	top: 107%;
	width: 270px;
	margin-top: 0;
	background: $headerBg;
	padding: 40px 30px;
	@include box-sizing(border-box);
}

/*================ Sub-menu lvl1 item  ===============*/
.sf-menu .simple_menu {
	li {
		position: relative;
	}
	a{
		display: block;
		color: $primary;
		padding: 0;
		font-size: 16px;
		line-height: 30px;
	}
	li.active > a, a:hover, li.sfHover > a {
		color: $primary;
	}
}

/*================ Sub-menu lvl2 ===============*/
.sf-menu .simple_menu .simple_menu {
	left: 10px;
	top: 30px;
	width: 115%;
	margin-top: 0;
	&:before{
		display: none;
	}
}

.menu-gadget {
	display: none!important;
}

/*--------------------------------------------------------------
                      Megamenu Styles
--------------------------------------------------------------*/
.sf-menu .sf-mega{
	display: none;
	position: absolute;
	list-style-type: none;
	top: 100%;
	left: 0;
	right: 0;
	margin-top: -10px;
	@media (max-width:1199px) {
		margin-top: 0px;
	}
	z-index: 109;
	padding: 0;
	text-align: left;
	background: rgb(248, 248, 248);
	color: $primary;
	background-repeat: no-repeat;
	width: 250px;
	.container,[class*="col-"],.row{
		width: 100%;
		padding: 0;
		margin: 0;
	}
	>li{
		padding: 0;
	}
	li{
		.sf-mega_section{
			display: block;
			width: 100%;
			padding: 0;
			ul{
				&>li{
					display: block;
					margin-bottom: 6px;
					width: 100%;
					padding: 0 10px;
					&:hover{
						background: #eee;
					} 
					a {
						&:hover {
							color: $secondary;
						}
						img {
							width: 70px;
							height: 70px;
							margin: 5px 10px 5px 0;
						}
					}
				}
			}
		}
		.submenu_title{
			margin-bottom: 14px;
			a{
				color: #fff;
				font-size: 16px;
				line-height: 26px;
				text-transform: uppercase;
				font-weight: 700;
				&:hover{
					color: $primary;
					background: none;
				}        
			}
		}
		a{
			color: $primary;
			margin-bottom: 0;
			font-size: 18px;
			line-height: 30px;
			font-weight: 400;
			display: inline-block;
			text-shadow: none;
			span {
				display: inline-block;
				width: calc(100% - 85px);
				line-height: 22px;
				vertical-align: middle;
			}
			&:hover{
				color: $primary;
				background: none;
			}
		}
	}
	ul{
		padding: 0;
		list-style-type: none;
	}
	&_row{
		@include clearfix;
	}
	&_section{
		float: left;
		padding: 0 15px;
	}
}

/*--------------------------------------------------------------
                      sf-with-ul styles
--------------------------------------------------------------*/

.sf-menu > li > .sf-with-ul {
	position: relative;
	&:before {
		position: absolute;
		left: 50%;
		top: 68%;
		margin-left: -6px;
		content: '\f0d7';
		font: 400 12px/12px 'FontAwesome';
		display: none;
	}
}

.sf-menu ul > li > .sf-with-ul {
	position: relative;
	&:after {
		position: absolute;
		right: 0;
		top: 50%;
		margin-top: -7px;
		content: '\f0d7';
		font: 400 14px/14px 'FontAwesome';
	}
}

@media (max-width: 1199px) {
	.sf-menu > li {
		padding: 0 1%;
		margin: 5px 12px;
		height: 50px;
		> a {
			line-height: 50px;
			font-size: 13px;
			i{
				font-size: 18px;
				line-height: 23px;
				margin-right: 2px;
			}
		}
	}
}
@media (max-width: 991px) {
	.navigation {
		top: 87px;
		.sf-menu .sf-mega{ 
			margin-top: 0;
		}
	}
}
@media (max-width: 991px) {
	.sf-menu > li {
		height: 45px;
		margin: 0;
		a {
			font-size: 11px;
		}
	}
	.sf-menu .sf-mega{
		padding: 6px;
		li{
			.submenu_title{
				a{
					font-size: 18px;
					line-height: 24px;
				}
			}
		}
	}
}
@media (max-width: 767px) {
	.sf-menu {
		display: none;
	}
	.sf-mega_section{
		width: 100% !important;
	}
}