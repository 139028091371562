/*==================   Lazy Img    ======================*/

$lazy-bg: #FFF;

.lazy {
	display: block;
	position: relative;
	height: 0;
	overflow: hidden;
	img {
		position: absolute;
		top: 50%;
		left: 0;
		right: 0;
		bottom: 0;
		text-align: center;
		height: auto;
		z-index: 0;
		@include transform(translateY(-50%));
		@include transition(1s all ease);
	/*	opacity: 0;*/
		-webkit-backface-visibility: hidden;
	}

	&.lazy-loaded {
		background: none;
		img {
			opacity: 1;
		}
	}
}