/*========================================================
                        Buttons
=========================================================*/
#button-search {
	+ h2 {
		margin-top: 15px;
	}
}
.btn-primary:active {
	background :#6A9BCE;
	border-color: #6A9BCE;
}
.buttons {
	margin: 1em 0;
	@include clearfix;
	.btn{
		margin-bottom: 10px;
		&:hover{
			@include box-shadow(none);
		}
		i, span {
			vertical-align: middle;
		}
		i{
			font-size: 24px;
			margin: 0 5px;
			display: inline-block;
			line-height: $baseLineHeight;
		}
	}
	.pull-right {
		.btn{
			margin-right: 10px;
		}
		[for="agree1"] + .btn {
			margin-bottom: 0;
		}
	}
}
.input-group-btn .btn {
	i {
		font-size: 15px;
		line-height: 18px;
	}
}

.agree{
	b{
		@include transition(.3s all ease);
	}
	&:hover b{
		color: $primary;
	}
}

/* ================   Default Buttons BEGIN   =============== */

.btn, .btn-1, .btn-default, .btn-primary, .btn-success, .btn-info, .btn-warning,  .product-btn-add {
	@include border-radius(10px);
	position: relative;
	letter-spacing: .9px;
	text-transform: uppercase;
	padding: 8px 21px;
	font-weight: 500;
	line-height: 21px;
	font-size: 16px;
	color: #fff;
	@include transition(all 0.4s ease);
	display: inline-block;
	background-color: #6A9BCE;
	z-index: 1;
	text-decoration: none;
	&:hover {
		background-color: #0264AF;
	}
	& span {
		position: relative;
		z-index: 3;
		color: #fff;
	}
	& i{
		display: none;
		margin-right: 5px;
		position: relative;
		z-index: 3;
	}
}

.showhide {
	position: absolute;
	right: 10px;
	top: 10px;
	padding: 8px 20px;
	color: #fff;
	background: #6A9BCE;
	border-radius: 10px;
	&:hover, &:active, &:focus {
		background: #1C60B5;
		color: #fff !important;
	}
	@media (max-width: 480px) {
		position: static;
	}
}

@media (min-width: 768px) {
	.product-grid{
		.btn, .product-btn-add { 
			margin: 0 auto;
			margin-bottom: 10px !important;
			display: block;
		}
	}
}

.btn-1 {
	@include border-radius(5px);
	padding: 15px 17px 16px;
	&:after{
		@include border-radius(5px);
	}
	span{
		font-size: 21px;
	}
}

.form-horizontal{
	.input-group-btn{
		.btn.btn-default{
			&:after{
				@include border-radius(0 25px 25px 0);
			}
		}
	}
}
.account-return-add{
	.btn.btn-default{
		i{
			display: block;
		}
	}
}
.btn-default:hover{
	color: #fff;
}

.btn-danger{
	font-size: 13px;
	color: $textPrimary;
	text-shadow: none;
	text-transform: uppercase;
	border-radius: 4px;
	border: none;
	padding: 8px 16px 7px;
	outline: none;
}

.btn-icon {
	& i{
		display: block;
		font-size: 24px;
		color: gray;
	}
	&:after{
		display: none;
	}
	&:hover{
		i{
			color: $primary;	
		}
	}
}

input.btn.btn-primary{
	&:hover{
		background: $primary;
	}
}

/*================   Product Buttons BEGIN   ===============*/
.product-btn {
	color: textTetriary;
	padding: 0;
	i{
		font-size: 24px;
	}
	&:hover {
		color: $primary;
		background: none;
	}
}



/* ================   QuickView  =============== */
.product-thumb .image > .quickview {
	position: absolute;
	left: 50%;
	top: 50%;
	display: inline-block;
	opacity: 0;
	padding: 0;
	width: 54px;
	height: 54px;
	line-height: 52px;
	@include translate(-50%,-50%);
	z-index: 1;
	border-radius: 50%;
	letter-spacing: 0;
	font-size: 18px;
	text-transform: uppercase;
	font-weight: 400;
	color: #fff;
	@include transition(all 0.4s ease);
	background-color: $primary;
	z-index: 1;
	i{
		margin: 0;
	}
	&:hover {
		background: $secondary;
	}
	& span {
		position: relative;
		z-index: 3;
	}
}


/* ================   Product page buttons  =============== */
.product-buttons {
	@include clearfix;
	margin: 10px 0 1em;
	padding: 0;
	li {
		display: inline-block;
		margin-right: 10px;
		list-style: none;
		width: 100%;
		&+li {
			margin-top: 5px;
		}
	}
}

#button-cart {
	display: inline-block;
	margin-right: 15px;
	vertical-align: middle;
	padding: 8px 35px;
	font-size: 14px;
	&:disabled {
		pointer-events: none;
		opacity: .5;
	}
}
.product-buttons {
	#button-cart {
		width: 292px;
	}
	#button-cart-credit, #button-cart-installment {
		font-size: 11px;
		padding: 5px 9px;
		margin-right: 10px;
		span {
			color: #363441;
		}
	}
}
#button-cart-credit,#button-cart-credit-qv, #button-cart-installment {
	font-size: 14px;
	padding: 8px;
	background: #ccc;
	@include transition(all 0.4s ease);
	&:hover {
		background:#aaa;		
	}
}

/* ================   Tooltip  ================= */
.tooltip-inner {
	background: $primary;
}
[class][class].tooltip-arrow {
	border-top-color: $primary;
}
/*========================================================
                 Responsive styles Buttons
=========================================================*/

@media (max-width: 1199px) {

	.btn.focus, .btn:hover,
	.btn:visited, .btn-default, .btn-primary, .btn-success, .btn-info, .btn-warning, .btn, .product-btn-add {
		letter-spacing: 0;
		padding: 7px 20px;
		font-size: 15px;
	}

}

@media (max-width: 991px) {
	.product-thumb{
		.product-btn-add{
			padding: 12px 15px;
			font-size: 14px;
		}
	}
}
@media (min-width: 992px) and (max-width: 1199px) {
	.account-wishlist table td .btn {
		padding: 10px;
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.table-bordered > tbody > tr > .text-right {
		padding: 5px;
	}
	.account-wishlist table td .btn {
		padding: 6px;
	}
}

@media (max-width: 767px) {
	#grid-view,#list-view {
		display: none;
	}

	.product-thumb .image > .quickview {
		display: none;
	}

	.btn.focus, .btn:hover,
	.btn:visited, .btn-default, .btn-primary, .btn-success, .btn-info, .btn-warning, .btn, .product-btn-add {
		padding: 8px 12px 7px !important;
	}
}


@media (max-width: 479px) {
	.buttons {
		.pull-left, .pull-right {
			float: none !important;
			margin-left: 0;
		}
	}
	#button-cart{
		display: block;
		margin: 15px 0;
		width: 100%;
	}
	.catlist-buttons .callback {
		padding: 8px 6px 7px !important;
	}
}

@media (max-width: 410px) {
	.account-address {
		.table td a {
			display: block;
			+ a {
				margin-top: 5px;
			}
		}
	}
}

@media (max-width: 370px) {
	#button-cart-credit, #button-cart,#button-cart-credit-qv {
		font-size: 13px;
	}
	#product .product-btn-add {		
		min-width: 135px !important;
	}
}

.btn-block{
	.counter{
		margin: 2px;
		color: #333!important;
		&:hover{
			color: #fff!important;
		}
	}
}
