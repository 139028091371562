$dropdown-bg: #494753;
#reserve_form .modal-dialog {
	z-index: 99999;
}
header {
	background: #fff;
	.common-home & {
		background: rgba(255,255,255,0.2);
	}
	color: $primary;
	position: fixed;
    height: 167px;
    width: 100%;
    z-index: 9999;
	@media (min-width:992px) and (max-width: 1199px){
		height: 157px;
	}
	@media (min-width:768px) and (max-width: 991px){
		height: 137px;
	}
	.common-home & {
		margin-bottom: 0;
		position: fixed;
		z-index: 1999;
		width: 100%;
	}
	.top-line{
		position: relative;
		padding: 9px 0 10px;
		border-bottom: 1px solid $borderColor1;
	}
	.mid-line{
		position: relative;
		background: white;
		.common-home & {
			background: transparent;
		}
	}	
	.box-language, .box-currency, .top-menu {
		display: inline-block;
	}
	.box-language + .box-currency {
		margin-left: 20px;
	}
	.separator{
		position: relative;
		margin: 0 22px;
		height: 35px;
		width: 1px;
		background: $borderColor1;
		padding: 0;
		border: 0;
		top: 10px;
		float: right;
	}
	.check{
		float: right;
		position: relative;
		margin-left: 35px;
		a{
			border-radius: 27px;
			position: relative;
			letter-spacing: .7px;
			text-transform: uppercase;
			padding: 16px 22px 18px;
			font-weight: 500;
			line-height: 20px;
			font-size: 16px;
			color: #fff;
			background-image: linear-gradient(to top, $secondaryDark, $secondary);
			@include transition(all 0.4s ease);
			display: inline-block;
			-webkit-backface-visibility: hidden;
			z-index: 1;
			&:after {
				position: absolute;
				content: '';
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				border-radius: 27px;
				background-color: $primaryDark;
				background-image: linear-gradient(to top, $primaryDark, lighten(saturate(adjust_hue($primaryLight, -13.547497043752458), 11.340206185566998%), 3.3333333333333357%));
				transition: opacity 0.4s ease-out;
				z-index: 2;
				opacity: 0;
			}
			&:hover:after {
				opacity: 1;
			}
			& b {
				position: relative;
				z-index: 3;
				color: #fff;
			}
			& i{
				display: none;
				margin-right: 5px;
				position: relative;
				z-index: 3;
			}
		}
	}
	address{
		display: inline-block;
		margin: 0 20px;
		line-height: 36px;
		position: relative;
		float: right;
		text-align: right;
		i{
			position: relative;
			top: 0;
			font-size: 23px;
			margin: 0 3px;
		}
		a{
			font-size: 21px;
			letter-spacing: 1px;
			font-weight: bold;
			color: $textTetriary;
			&:hover{
				color: #fff;
			}
		}
		span{
			line-height: 36px;
			margin-right: 5px;
			color: $textTetriary;
		}
	}
	.box-cart {
		float: right;
		margin-left: 35px;
		margin-top: 8px;
	}
	.logo {
		float: left;
		font-size: 11px;
		font-size: 11.8px;
		@media (max-width: 1199px) {
			font-size: 10.5px;
		}
		line-height: 12px;
		max-width: 150px;
		img {
			image-rendering: -webkit-optimize-contrast; /* Chrome (and Safari) */
			image-rendering: optimize-contrast;         /* CSS3 Proposed       */
			-ms-interpolation-mode: nearest-neighbor;   /* IE8+                */
		}
		a {
			text-align: center;
		}
	}
	.nav {
		float: right;
	}
	.wrapp{
		position: relative;
	}
	.product-compare-header {
		float: right;
		text-align: center;
		margin-top: 13px;
		a{
			color: $primary;
			&:hover {
				color: #0264AF;
			}
			i {
				font-size: 28px;
			}
			p {
				margin: 0;
				font-size: 13px;
				line-height: 19px;
			}
			.compare-total2 {
				font-weight: 500;
				position: relative;
				top: -4px;
			}
		}
	}
	.number-phone, .worktime {
		margin-top: 13px;
		color: $primary;
		i {
			font-size: 30px;
			margin-right: 8px;
			line-height: 47px;
		}
		p {
			font-size: 13px;
		}
		span {
			font-size: 16px;
			color: $primary;
			display: block;
			line-height: 22px;
			a {			
				color: $primary;
				&:hover {
					color: $primary;
				}
			}
		}
	}
	.worktime {
		margin-left: 20px;
	}
	.number-phone {
		margin-left: 30px;
	}
	.search_block {
		margin-top: 17px;
	}	
}


/*================  Toggle ===============*/
.toggle-wrap {
	position: relative;
}
.toggle {
	&_cont {
		display: none;
		background: $dropdown-bg;
		padding: 0;
		list-style-type: none;
		position: absolute;
		left: 0;
		top: 124%;
		width: 180px;
		z-index: 999;
		> li {
			> a, > button {
				text-align: left;
				width: 100%;
				display: block;
				padding: 5px 5px 5px 10px;
				text-transform: capitalize;
				font-size: 13px;
				&:hover {
					background: $primary;
					color: #FFF;
				}
			}
		}
		ul {
			text-align: right;
			font-size: 16px;
			z-index: 999;
			li + li {
				margin-top: 1px;
			}
		}
		&__right {
			left: auto;
			right: 0;
			> li > a{
				color: #fff;
				&:hover{
					background: $textTetriary;
					color: #fff;
				}	
			}
		}
	}
}


/*================   Top Links style   ===============*/
$top-links-item-margin: 14px;

header .nav {
	position: relative;
	top: 0;
	@media (min-width: 768px) {
		> .toggle {
			display: none;
		}
		> .toggle_cont {
			display: block !important;
			position: static;
			padding: 0;
			margin: 0;
			background: none;
			width: auto;
			> li {
				display: inline-block;
				+ li {
					margin-left: $top-links-item-margin;
				}
				> a {
					display: inline-block;
					font-size: 16px;
					font-weight: 500;
					padding: 0;
					position: relative;
					height: 40px;
					line-height: 35px;
					text-align: center;
					width: 40px;
					vertical-align: top;
					border: 2px solid $borderColor1;
					@include border-radius(50%);
					&:hover, &.current {
						color: $textTetriary;
						background: none;
					}
					i{
						font-size: 17px;
						font-weight: normal;
						margin: 0;
						position: relative;
						top: 0;
					}
					span{
						display: none;
					}
				}
			}
			.common-home & > li.first > a {
				color: $primary;
			}
		}
	}
	@media (max-width: 767px) {
		position: fixed;
		top: 0;
		margin: 0;
		z-index: 10000;
		@include transition(0.5s all ease);
		>.toggle{
			&:hover {

			}
		}

		.toggle-wrap {
			.toggle {
				display: none;
			}
			.toggle_cont {
				display: block !important;
				height: auto !important;
				position: static;
				padding: 0;
				@include transition(0);
			}
		}
		.toggle_cont li a{
			color: #fff;
			&:hover{
				background: $textTetriary;
				color: #fff;
			}
		}
	}
}

/*================  CURRENCY AND LANGUAGE BOXES ===============*/
.box-language,
.box-currency {
	.toggle {
		display: block;
		text-align: center;
		font-size: 16px;
		line-height: 35px;
		text-transform: capitalize;
		cursor: pointer;
		margin-bottom: 0;
		@include transition(.3s all ease);
		&:after {
			display: inline-block;
			content: '\f107';
			margin-left: 2px;
			font-size: 15px;
			font-weight: 400;
			font-family: $fa;
		}
		i{
			font-style: normal;
			display: inline-block;
			position: relative;
			height: 40px;
			width: 40px;
			font-weight: 500;
			border: 2px solid $borderColor1;
			@include border-radius(50%);
		}
		&.active, &:hover {
			color: $textTetriary;
		}
		&_cont {
			> li {
				> a, > button {
					&:hover, &.selected {
						background: $textTetriary;
						color: #fff;
					}
				}
				& + li {
					margin-top: 1px;
				}
			}
		}
	}
}

/*========================================================
               Responsive Header styles
=========================================================*/

@media (max-width: 1199px) and (min-width: 992px) {
	.header_phones.col-md-4 {
		width: 40%;
		.number-phone, .worktime {
			i {
				font-size: 26px;
				line-height: 40px;
			}
			span {
				font-size: 14px;
				line-height: 19px;
			}
		}
	}
	.header_cart.col-sm-3 {
		width: 18%;
	}
}

@media (max-width: 1199px) {
	header {
		.separator {
			margin: 0 17px;
		}
		.check{
			margin-left: 20px;
		}
		address{
			float: right;
		}
	}
}

@media (max-width: 991px) and (min-width: 768px)  {
	.header_cart {
		padding: 0;
		width: auto !important;

		.box-cart {
			margin-left: 7px;
			#compare-total {
				font-size: 11px;
			}
		}
	}
	.header_phones {
		.number-phone{
			margin-left: 23px;
		} 
		.worktime {
			margin-left: 12px;
		}
		span {
			font-size: 12px !important;
		}
		padding: 0;
	}
	.header_search {
		padding: 0;
		#search1 {
			width: 100%;
		}
	}
}

@media (max-width: 991px) {
	header {		
		address{
			margin: 0;
			line-height: 22px;
			text-align: right;
			span{
				display: block;
				margin-right: 0;
			}
		}
		.nav{
			float: left;
			margin-left: 5px;
			.toggle_cont {
				> li + li {
					margin-left: 1px;
				}
				.toggle_cont__right{
					left: 0;
				}
			}
		}
		.box-language, 
		.box-currency {
			float: left;
			.toggle{
				&:after{
					display: none;
				}
			}
		}
		.box-language + .box-currency {
			margin-left: 5px;
		}
		.check{
			margin-left: 15px;
		}
		.separator{
			margin: 0 10px;
		}
		#cart{
			button{
				strong{
					display: none;
				}
			}
		}
		.wishlist{
			b{
				display: none;
			}
		}
		.search__cnt{
			margin-right: 8%;
			&.open{
				width: 92%;
			}
		}
	}
}


@media (max-width: 767px) {
	header {
		#stuck {
			padding-top: 10px;
		}
		.search {
			.tog_btn{
				top: 27px;
			}
			&__btn{
				width: auto;
				padding: 0 15px;
			}
			&__cnt{
				margin-right: 14%;
			}
			&__cnt.open{
				width: 86%;
			}
		}
		.box-language{
			float: left;

			+ .box-currency {
				padding: 0;
				margin: 0 5px;
				float: left;
			}
		}
		.logo {
			float: none;
			padding-top: 0;
			margin-bottom: 0;
			img {
				display: block;
			}
		}
		.box-cart, .product-compare-header {
			float: none;
			margin-top: 0;
		}
		.check {
			margin-left: 10px;
		}
		.separator{
			margin: 0 10px;
		}
		address {
			span{
				display: block;
			}
		}
		.mid-line {
			padding: 0;
			.header_phones {
				padding: 0;
				.number-phone{ 
					margin-bottom: 5px;
				}
				.number-phone, .worktime {						
					&>.pull-left{
						text-align: right;
					}			
					float: right !important;
					margin: 0;
					margin-right: 10px;
					i {
						font-size: 24px;
						line-height: 35px;
					}
					span {
						font-size: 12px;
						line-height: 17px;
					}
				}
			}
		}
	}
}

.number-phone, .worktime {						
	@media (max-width: 500px) {
		margin-right: 5px;
		margin-left: -15px;
		clear: both;
	}
}
@media (max-width: 640px) {
	header {
		.check {
			margin-left: 3px;
			a {
				border: 0;
				border-radius: 0;
				background: none;
				padding: 0;
				top: 14px;
				position: relative;
				i{
					@include transition(all 0.4s ease);
					display: inline-block;
					margin-right: 0;
					font-size: 34px;
					color: $primary;
				}
				b{
					display: none;
				}
				&:after{
					display: none;
				}
				&:hover{
					background: none;
					i{
						color: $textTetriary;
					}
				}
			}
		}		
	}
}

@media (max-width: 480px) {
	header{
		.logo{
			position: relative;
			z-index: 1;
		}
		.wishlist{
			z-index: 1;
		}
		.col-xs-3{
			width: 50%;
		}
		.col-xs-9{
			float: none;
			width: 100%;
		}
	}
	.search input[type="text"] {
		padding: 11px 25px 11px 10px !important;
	}
	.dropdown-menu.autosearch {
		width: 100vw;
		min-width: auto;
		left: -54px;
		padding: 0;
	}
}
@media (max-width: 320px) {
	.dropdown-menu.autosearch {
		width: 280px;
	}
}