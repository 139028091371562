/*==================  Stuck menu  ======================*/

$stuck-bg: $headerBg;

.stuck-menu{
	width: auto !important;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 10000;
	background: #fff;
	padding-top: 14px;
}
.ajax-overlay-open .scroll-fix{
	z-index: 996;
}

@media only screen and (min-width: 767px) {
	.scroll-fix {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		z-index: 10000;
		@include transition(.4s all ease);

		// Custom styles
		background: #fff;
	padding-top: 14px;
	}
}

@media only screen and (max-width: 767px) {
	.stuck-menu {
		position: static;
		+.stuck-menu{
			display: none;
		}
	}
}