#page {
	background: $contentBg;
	overflow: hidden;
}
body[class^=product-] #page {
	background: #f7fbff;
}

#content{
	padding-bottom: 30px;
}
section.bottom {
	background: $footerBg;
}
.product-allproduct h1:after {
	background: transparent;
}
.product-acc .product-grid {
	padding-top: 21px;
}

/*==================  Marked List  ======================*/
.box.account ul, .box.affilate ul {
	padding: 0;
	list-style-type: none;
	li+li{
		border-top: 1px solid $borderColor;
	}
	a {
		position: relative;
		background: $stockBg;
		display: block;
		padding: 13px 15px;
		&:hover{
			background: $stockBgHover;
			color: $textSecondary;
		}

	}
	a:hover {
		color: $textSecondary;
	}
}

.manufacturer-content div, #content ul.list-unstyled {
	margin: 0;
	list-style-type: none;
	li+li{
		margin-top: 10px;
		position: relative;
	}
	a {
		position: relative;
		color: $textPrimary;
		&:hover{
			color: $primary;
		}
		&:before {
			content: '\e11d';
			margin-right: 5px;
			font-weight: 400;
			font-size: 20px;
			font-family: $material;
			color: $primary;
		}
	}
}

.contact-blocks {
	position: relative;
	margin: 50px 0;
	@media (max-width:767px) {
		margin: 0;
		.col-sm-6 {
			margin-bottom: 10px;
		}
	}
	.contact-icon {
		display: inline-block;
		width: 50px;
		height: 50px;
		border-radius: 50%;
		background: $primary;
		top: 0;
		position: absolute;
		i.fa {
			color: #fff;
			font-size: 30px;
			padding: 11px 10px 10px 10px;
			width: 50px;
			text-align: center;
		}		
	}
	.contact-text {
		display: inline-block;
		width: calc(100% - 50px);
		padding-left: 60px;

	}
}

#content .product-info ul.list-unstyled a:before{
	display: none;
}
.quewstions {
	td, th {
		padding: 10px;
	}
}
/********************  HOME   ****************************/
.common-home
{
	#stuck {
		padding: 14px 0;
		@media (max-width:991px) {
			padding: 10px 0 0 0;
		}
		@media (max-width:767px) {
			padding: 58px 0 0 0;
		}
	}
	#page {
		&>.container {
			display: none;
		}
		#section5 {
			.fp-scrollable {
				width: 100%;
			}
		}
	}
	.sf-menu .sf-mega{
		background: rgba(255, 255, 255, 0.58);
		li a {
			color: #0264af;
		}
	}
	.navigation {
		background: transparent;
	}
	#fullpage {
		.section {
			.mobheader a {
				&:hover, &:active, &:focus {
					color: #fff;
				}
			}
			background: center center no-repeat;
			background-size: cover;
			.block {
				.gotobtn {
					clear: both;
					display: block;
					position: static;
					text-align: center;
					a {
						box-shadow: 2px 2px 2px rgba(105,105,105,.5);
						background: rgba(255,255,255,.4);
						border-radius: 30px;
						text-shadow: 1px 2px 3px #666;
						border-top: 1px solid rgba(255,255,255,.5);
						border-left: rgba(255,255,255,.5) 1px solid;
						padding: 8px 21px 7px;
						font-size: 20px;
						position: relative;
						text-transform: uppercase;
						font-weight: 500;
						line-height: 32px;
						transition: all 0.4s ease;
						display: inline-block;
						-webkit-backface-visibility: hidden;
						z-index: 1;
						text-decoration: none;
						>span {
							position: relative;
							z-index: 3;
							color: rgba(255,255,255,.9);
							text-shadow: 1px 2px 3px #666;
						}
						&:hover {
							background: rgba(255, 255, 255, 0.5);
						}
					}
				}
				height: 100vh;
				.product-btn-add {
					background-image: linear-gradient(to top, #ddd, #fff);
					border: 1px #999 solid;
					span {
						color: #333;
					}
					&:after {
						background-image: linear-gradient(to top, #eee, #ccc);
						border: 1px #bbb solid;
					}
				}
				padding: 200px 100px 70px 100px;
				@media (max-height:800px) {
					padding: 167px 100px 70px 100px;
				}
				&.footerblock {
					height: auto;
					padding: 200px 0px 0px 0px;
				}
				.textblock {
					margin-top: 10%;
					p {
						color: #fff;
						font-size: 24px;
						line-height: 30px;
						@media (max-height:670px) {
							font-size: 19px;
							line-height: 25px;
						}
					}
					h2 {
						font-family: 'gilroy', sans-serif;
						font-size: 7em;
						color: #fff;
						a:hover {
							color: #fff;
						}
						@media (max-height:670px) {
							font-size: 76px;
						}
					}
				}
				.imgblock {
					display: inline-block;
					width: 100%;					
					margin-top: 10%;
					cursor: pointer;
					position: relative;	
					@media (max-width:991px) {
						width: 280px;
						height: 280px;
						margin: 22% 0;
					}
					img {
						max-height: 600px;
						@media (max-height:670px) {
							max-height: 400px;
						}
						position: absolute; 
						top: 0; 
						left: 0;
						&.nrm {
							opacity: 1; 							
							transition: all ease-in 0.5s;
						}
						&.hvr {
							opacity: 0; 
							transition: all linear 0.5s;
						}
					}
					&:hover {
						img.nrm {opacity: 0;}
						img.hvr {opacity: 1;}
					}
				}				
				@media (max-width:1299px) {
					.imgblock {
						margin: 0;
					}
					.textblock {
						margin: 0;
						h2 {
							margin-top: 50px;
							font-size: 6em;
						}
					}
				}		
				@media (max-width:1199px) {
					.textblock {
						h2 {
							font-size: 4em;
						}
					}
				}
				@media (max-width:991px) {
					.textblock {
						margin-top: 15px;
						h2 {
							font-size: 4em;
							padding-top: 50px;
						}
						p {
							font-size: 20px;
							line-height: 26px;
							top: 28vh;
						} 
					}					
					.imgblock {
						a {
							height: 23vh;
						}
						width: 100%;						
						img {
							position: absolute;
							left: 50%;
							right: auto;
							transform: translateX(-50%);
							height: 23vh;
							@media (max-width:767px) {		
								@include transform(translateX(-50%));
							}
							@media (max-width:460px) {								
								@include transform(translateX(-15%));
							}
						}
					}
					.mobheader {						
						width: 100%;
						margin: 0 0 0 17px;
						padding: 0;
						color: #fff;
						font-size: 45px;
						bottom: 10px;
						position: static;
						text-align: center;
					}
				}
				@media (max-width:767px) {							
					.mobheader {
						width: 50%;
						margin: 0 0 0 17px;
						font-size: 36px;
					}
				}
				@media (max-width:333px) {							
					.mobheader {
						width: 38vw;
						/*	font-size: 24px;*/
					}
				}
				@media (max-width:767px) {
					&, &.footerblock {
						padding: 129px 0 0 0;
						.owl-controls {
							display: none !important;
						}
					}
					.imgblock {
						height: 33vh;
						width: 100%;
						img {
							height: 35vh;
							position: absolute;
							left: initial;
							right: -38px;
							@include transform(translateX(-50%));
							@media (max-width:570px) {								
								@include transform(translateX(-15%));
							}
						}
					}
					.textblock {
						h2 {
							padding-top: 0;
							margin-top: 0;
							font-size: 3em;
							line-height: .8em;
						}
						p {
							font-size: 13px;
							line-height: 16px;
							padding: 0 20px;
						}
						@media (max-width:340px) {
							.smallscreen {
								display: none;
							}
						}
					}
					@media (max-width:490px) {
						h2 a {
							height: auto;
							width: 40%;
						}
						.imgblock img {
							height: 42vw;
							right: 0;
							top: 50%;
							@include translate(-15%,-50%);
						}
					}
				}
			}
			&.footer-section .block {
				height: calc(100vh - 353px);
			}
		}
		.box.featured {
			.box-heading {
				margin-bottom: 0;
				h3 {
					font-family: 'gilroy', sans-serif;
					font-size: 40px;
					@media (max-width:767px) {
						font-size: 23px;
					}
					color: #fff;
				}
			}
			.owl-carousel {
				background: transparent;
				.product-thumb {
					.name a{
						color: #fff !important;
					}
					.price {
						font-size: 26px;
						line-height: 26px;
						color: #fff;
					}
					.product-btn {
						color: #fff;
					}
				}
				.owl-buttons div {
					color: #fff;
					&.owl-prev {
						left: 10px;
					}
					&.owl-next {
						right: 25px;
					}
				}
			}
		}
	}
	.seemore {
		color: #fff;
		font-size: 70px;
		@media (max-width: 767px) {
			font-size: 40px;
		}
		@media (max-width: 480px) {
			display:none;
		}
		position: absolute;
		bottom: 0;
		left: 50%;
		@include transform(translateX(-50%));
		@keyframes seemore {
			0%   { opacity:1; }
			50%  { opacity:.7; }
			100% { opacity:1; }
		}
		i {
			cursor: pointer;
			animation-name: seemore;
			animation-duration: 1s;
			animation-iteration-count: infinite;
			transform: translateY(-6px);
			&:hover {
				color: #ccc;
				animation: none;
			}
		}
	}
	#homeparallax {	
		.staticlayer {
			position: absolute;
			top: calc(24vh + 167px);
			left: 50%;
			z-index: 999;
			@include transform(translateX(-50%));
		}
		@media (max-width: 991px) {
			.container {
				padding: 0;
				.sf-menu li {
					padding: 0 6px !important;
				}
			}
		}		
		@media (max-width: 767px) {				
			min-height: 222px;
			.layer {
				div {
					height: auto !important;
					transform: translateY(7px) !important;
				}
				img {
					width: 50vw;	
				}
				&:last-child div {
					top: 39vh !important;
					left: 5vw !important;
					width: 90vw !important;
					img {
						width: 90vw !important;
					}
				}
			}
		}
		@media (max-width: 380px) {				
			.layer {
				div {
					transform: translateY(7px) !important;
				}
			}
		}
		background: $contentBg url(/image/bg05.jpg) center 60% no-repeat;
		background-size: cover;
		height: 100vh;
		overflow: hidden;
		margin: 0;
		list-style: none;
		padding: 0;
		.layer {
			img {
				width: 20vw;				
			}
			&>div {
				position: absolute;
				display:inline-block;
				height: 300px;
				width: 500px;
			}
		}
	}
	.megamenu {
		margin: 0 auto;
		.sf-menu {
			.sf-mega_section {
				li.sfHover > a, li:hover > a {
					color: $secondary;
				}
			}
			@media (max-width: 1199px) {
				li {
					margin-right: 3px !important;
					&:first-child{
						/*	margin: 5px !important;*/
					}
				}
			}
			@media (max-width: 991px) {
				li {
					margin-right: 2px !important;
				}
			}
			li.sfHover > a, li > a:hover {
				color: #0264AF;					
				&:after {
					background: #0264AF;
				}
			}
		}
	}
}
.cat-wrapper h1 {
	color: #0264AF;
}
.common-home .sf-menu li a {
	text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.88);
	color: #fff;
}
#brands {
	margin-top: 50px;
	h3 {
		margin: 0;
	}
}
#logo, .footer-logo {
	svg {
		max-width: 155px;
		height: 44px;
	}
}
.footer-logo {
	text-align: center;
}
/*********************/

@media (max-width: 767px) {
	aside {
		margin-top: 20px;
		.container{
			padding: 0;
			width: 100%;
		}
	}
	#content{
		.col-sm-3{
			.image{
				margin-bottom: 20px;
			}
		}
	}
	.common-home {
		.stuck-menu {
			position: static;
		}
	}
	#homeparallax2 {
		height: 150px;	
	}
}

/*temp hardcode bagfix*/
@media (min-width: 1300px) and (max-width: 1352px) {
	.common-home {
		#section5 > .fp-scrollable{
			margin: 0 auto;
		}
	}
}