/*========================================================
                      Product
=========================================================*/

/* ===================   Product Layout   =================== */
.product-layout {
	margin-bottom: 40px;
}

.for-send-error-press {
	font-style: italic;
	margin: 15px 0;
}

.product-wrapper {
	background: center center no-repeat fixed;
	background-image: url(/image/bg/bg5.jpg);
	background-size: cover;	
	@media (max-width: 767px)  {
		background: center bottom no-repeat;
		background-attachment: scroll;
		background-image: url(/image/bg/bg5mob.jpg);
		background-size: cover;
	}
}

.product-thumb {
	background: transparent;
	position: relative;
	padding: 0px 0px 15px;
	.image {
		margin-bottom: 0;
		.owl-item & {
			padding-top: 0;
			@media (max-width: 767px)  {
				max-width: 200px;
				margin: 0 auto;
			}
			@media (max-width: 321px)  {
				max-width: 150px;
			}
		}
		.product-compare & {
			margin-bottom: 10px;
		}
	}
	.caption {
		position: relative;
		padding: 28px 0 10px;
		@media (max-width: 767px)  {
			padding: 0px 0 10px;
		}
		.rating {
			position: absolute;
			top: 0;
			@media (max-width: 767px) {
				top: -35px;
			}
			width: 100%;
			.fa {
				color: $yellow;
			}
		}
	}
	.cart-button {
		.product-btn-add + div {
			margin-top: 13px;
			@media (max-width: 767px)  {
				display: inline-block;
				vertical-align: top;
				margin-top: 6px;
			}
		}
	}
	.product-btn {
		margin: 0 8px;
		i{
			margin-right: 0;
		}
	}
}

.owl-carousel {
	.owl-item{	
		img {
			width: auto!important;
		}

	}
	.owl-buttons {
		.owl-prev{
			left: -18px;
		}
		.owl-next{
			right: -18px;
		}
		@media (max-width: 767px)  {
			.owl-prev{
				left: 10px;
			}
			.owl-next {
				right: 10px;
			}
		}
	}
}
.owl-wrapper-outer {
	border: none;
	box-shadow: none;
}

/* ===================   Product Image   =================== */
.product-thumb .image {
	position: relative;
	> a {
		display: block;
		img {
			margin: 0 auto;
			width: 100%;
		}
	}
}
@media (max-width: 767px)  {
	.productlist {
		.product-grid  {
			padding: 0 5px;
			.product-thumb {
				border: 1px #d8d8d8 solid;
				padding: 3px;
			}
		}
	}
}
/* ===================   Product Caption   =================== */
.product-thumb {
	text-align: center;
	position: relative;
	@include box-sizing(border-box);
	.aside {
		display: none;
	}
	.name {
		> a {
			display: inline-block;
			line-height: 23px;
			font-size: 16px;
			font-weight: 500;
			color: $primary;
			&:hover{
				color: $secondary;
			}
		}
	}
	.description {
		display: none;
		.product-list & {
			display: block;
		}
	}
	&:hover .image > .quickview {
		opacity: .85;
	}
}

/* ==================    Product List, Product Grid    =================== */
.product-list .product-thumb .caption, .product-list .product-thumb .cart-button {
	overflow: hidden;
}

.compare-btn {
	i {  
		margin: 0 3px;
		display: inline-block;
		vertical-align: middle;
	}
}

.productlist {
	.product-thumb {
		.made-in,
		.made-in-reddot {
			left: 0;
			width: 95px;
			top: 0;
		}
		.made-in {
			width: 40px;
		}
		.markdown-product {
			background: #ff3535;
		}
	}
}

.product-list {
	.product-thumb {
		margin-bottom: 30px;
		@media (min-width: 768px) {
			@include clearfix();
		}
		.image, .caption, .aside {
			float: left;	
			padding: 0 15px;
			width: calc(100% - 260px);
			@include box-sizing(border-box);
		}
		.image{
			padding-top: 28px;
			width: 228px;
		}
		.aside {
			width: 35%;
			display: block;
			text-align: left;
			padding-top: 32px;
			.btn {
				margin-top: 5px;
			}
			.btn.focus, .btn:hover, .btn:visited, .btn-default, .btn-primary, .btn-success, .btn-info, .btn-warning, .btn, .product-btn-add {
				padding: 10px 17px;
			}
			.price {
				margin-bottom: 20px;
			}
		}
		.caption {
			text-align: left;
			padding-top: 28px;
			@media (max-width: 767px) {
				text-align: center;
			}
			.catlist-buttons {
				margin-top: 10px;
			}
		}
		> .price, > .cart-button,
		> .price, > .cart-button, > .cart-buttons {
			display: none;
		}
	}
	+ .product-list .product-thumb {
		border-top: 1px solid $borderColor;
		padding-top: 30px;
	}
}

.product-list .product-thumb {
	margin-bottom: 0;
	@media(max-width:767px) {
		padding-top: 0 !important;
	}
	&:first-child {
		@media(max-width:767px) {
			border-top: 1px solid #dedde0;
		}
	}
}

.clearfix + .product-list .product-thumb {
	border-top: 1px solid $borderColor;
	padding-top: 30px;
}

.product-grid .product-thumb .image {
	float: none;
}


.product-grid {
	@media (min-width: 1200px) {
		.product-thumb{
			min-height: 477px;
		}
	}
}
.avail_colors {
	strong {
		display: block;
		margin-top: 25px;
	}
	ul {
		list-style: none;
		display: inline-block;
		padding: 0;
		margin-left: 5px;
		vertical-align: text-top;
		li {
			display: inline-block;
			margin-top: 10px !important;
			a {
				display: inline-block;
				height: 100%;
				width: 100%;
				@media (max-width: 379px) {
					max-width: 65px;
				}
			}
		}
	}
}
.adv_res {
	margin-top: 20px;

	.advantages {
		width: 100%;
		.col-sm-12 {
			width: 50%;
			margin-bottom: 10px;
			display: flex;
			@media (max-width: 1199px) {
				padding: 5px 0;
			}
			@media (max-width: 991px) {
				padding: 0;
				width: 100%;
				>div{
					padding: 0;
				}
				img {
					height: 64px !important;
				}
			}
			.img {
				padding: 0;
			}
			>div  {
				-webkit-box-align: center;
				-ms-flex-align: center;
				align-items: center;
			}
			img {
				height: auto;
				padding: 0;
			}		
		}
	}
}
.product-gallery {
	margin-top: 50px;
}

/* ==================   SALE   =================== */
.sale, .new-pr, .labelgift {
	background: none;
    display: inline-block;
    font-size: 14px;
    position: absolute;
    top: 0;
    z-index: 2;
    padding: 0;
    border-radius: 50%;
    text-transform: uppercase;
	width: 56px;
	height: 56px;
    font-weight: 700;
    text-align: center;
    color: #fff;
    line-height: 56px;
    [lang="ru"] & {
		font-size: 12px;
		letter-spacing: -1px;
    }	
}
#content {
	.new-pr {
		width: unset;
		height: unset;
		border-radius: 17px;
		line-height: initial;
		padding: 5px 20px;
		background: #fff;
		border: 2px solid #1c60b5;
		color: #1c60b5;
		letter-spacing: normal;
	}
}
.sale.gift + .new-pr {
	top: 65px;
}
.sale.gift {
	background: #ff3535;
}
.product-gallery {
	.new-pr,.sale  {
		top: 0;	
		right: 60px;
		z-index: 999;
	}
}

.made-in,
.made-in-reddot {
	display: block;
	width: 160px;
	position: absolute;
	left: 80px;
	z-index: 99;
}
.made-in {
	width: 61px;
}

.new-pr,.sale  {
	background: #6A9BCE;
	right: 17px;
}
.markdown-product {
	background: #ff3535;
}

.discount{
	position: absolute;
	left: 28px;
	top: 42px;
	font-size: 14px;
	line-height: 20px;
	font-weight: 400;
	color: $textTetriary;
}	

@media (min-width: 768px)  {
	.video-container {
		padding:0 100px
	}
}

/* ===================   Product Price    =================== */
.product-thumb {
	.price-new, .price-old, .price {
		font-size: 20px;
		font-weight: 700;
		margin-bottom: 10px;
		@media (max-width: 767px)  {
			margin: 0;
		}
	}
}

.price-new, .price-old, .price {
	display: inline-block;
	font-size: 32px;
	font-weight: 700;
	line-height: 23px;
	color: $textPrimary;
}
.price-product{
	padding: 11px 0 0;
	display: inline-block;
	.price-old{
		font-size: 16px;
		line-height: 23px;	
	}
	.price-old, .price-new { 
		margin-bottom: -2px;
	}
}
.product-grid {
	.price-product {
		display: block;
	}
}

.price-old {
	font-weight: 400!important;
	position: relative;
	font-size: 16px;
	line-height: 23px;
	color: $secondary;
	&:before{
		display: inline-block;
		position: absolute;
		left: 0;
		right: 0;
		height: 1px;
		background: $secondary;
		content: "";
		top: 50%;
	}
}

.price-section {
	margin-top: 20px;
	font-weight: 700;
	.price-new, .price-section .price-old {
		font-size: 30px;
		color: $textPrimary;
		line-height: 30px;
	}
	.price-old {
		font-size: 20px;
		line-height: 30px;
	}
	.reward {
		display: block;
		margin-top: 10px;
	}
}

.price-tax {
	font-size: 12px;
}

.price-total {
	font-weight: bold;
}

.price s {
	font-size: 15px;
}

.stock, .out-stock {
	font-weight: 700;
}

.stock {
	color: $primary;
}

.out-stock {
	color: #EE372A;
}

.product-thumb .price-tax {
	color: #999;
	font-size: 12px;
	display: block;
}

.product_markdown_page {
	.price-section, #product, .form-group, .form-horizontal .form-group, .product-buttons, #button-cart {
		margin: 0;
	}
	.product-buttons {
		&:before, &:after {
			content: unset;
		}
	}
	#product {
		.quantity {
			margin: 0;
			padding: 0;
		}
		.compare-btn, #button-cart-credit, #button-cart-installment {
			margin: 0;
		}
		.product-btn-add {
			float: unset;
		}
	}
	.price-section {
		.price-new, .price-old {
			line-height: 1;
		}
	}
	.cheaper-products {
		margin: 20px 0;
	}
	.cheaper-item {
		padding: 20px;
		border: 1px solid #6d6d6d;
		width: 100%;
		max-width: 335px;

		&:not(.general) {
			border: 1px solid #969696;

			.price-new {
				font-size: 24px;
			}
		}
		&:hover {
			text-decoration: none;
		}
	}
	.cheaper-products, .cheaper-items, .cheaper-item, .cheaper-item-info, #product, .product-buttons {
		display: flex;
		flex-direction: column;
		gap: 16px;
	}
	.wrapper-qty-compare, .wrapper-btn-credit, .price-section {
		display: flex;
		gap: 8px;
	}
	.price-section {
		align-items: flex-end;
	}
	.cheaper-products, .cheaper-item {
		&__title {
			font-size: 16px;
			font-weight: 600;
			color: #363441;
		}
		&__status {
			font-weight: 600;
		}
	}
	.product-gallery {
		.image-thumb {
			.bx-prev,.bx-next {
				top: calc(50% - 55px);
			}
		}
	}
}

/* ===================   Product Quickview   =================== */
.quick_info {
	display: none;
}

.fancybox-skin:hover .fancybox-nav span {
	visibility: visible;
}

.fancybox-nav {
	height: 40px;
	top: 50%;
	width: 40px
}

.fancybox-next {
	right: -60px;
}

.fancybox-prev {
	left: -60px;
}

.quickview_description {
	overflow: auto;
	max-height: 300px;
	border: 1px solid $borderColor;
	padding: 10px;
	margin-top: 15px;
}

.quickview_description iframe {
	width: 40% !important;
	height: 200px !important;
	float: left;
	margin-right: 2em;
}

.quickview-style {
	h2{
		text-align: left;
	}
	.inf {
		font-size: 14px;
		margin-bottom: 10px;
	}
	.product-btn-add{
		margin-top: 10px;
	}
	.cart-button {
		margin-top: 10px;
	}
	.rating {
		margin-top: 5px;
	}
}

.cart-buttons{
	margin-top: 17px;
	.product-btn{
		padding: 0 4px;
		i{
			margin: 0;
		}
	}
}

.product-thumb .product-options {
	display: none;
}

.ajax-overlay {
	.product-options {
		position: relative;
		display: inline-block;
		vertical-align: middle;
		max-width: 450px;
		min-width: 300px;
		padding-bottom: 0;
		border-bottom: none;
		max-height: 80%;
		backface-visibility: hidden;
		h3 {
			margin-top: 0;
		}
		.col-sm-8 {
			text-align: left;
		}
		.options {
			padding: 20px;
			text-align: center;
			background: #FFF;
			@include border-radius(15px);
			@include box-shadow(1px 1px 8px rgba(#000, .8));
		}

		.ajax-overlay_close {
			position: absolute;
			right: 10px;
			top: 10px;
			font-size: 16px;
			color: rgba(#000, .5);
			font-family: $material;
			text-shadow: 1px 1px 5px rgba(#fff, .2);
			&:before {
				content: '\e054';
			}
			&:hover {
				color: #000;
			}
		}
	}
	.product-option-wrap {
		text-align: center;
		height: 100%;
		&:before {
			content: '';
			display: inline-block;
			height: 100%;
			vertical-align: middle;
		}
	}

}

.ajax-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	visibility: hidden;
	opacity: 0;
	@include transition(opacity .3s ease-in-out);
	z-index: 997;
	&.visible {
		opacity: 1;
		background: rgba(#000, .7);
		visibility: visible;
	}
}

/*========================================================
               Responsive Styles Product
=========================================================*/

@media (max-width: 1200px) {
	.product-thumb .button-group button, .product-thumb .button-group button + button {
		width: 33.33%;
	}

	.made-in,
	.made-in-reddot {
		top: 40px;
		left: 40px;
	}
	.made-in {
		width: 61px;
	}

	.productlist {
		.product-thumb {
			.made-in-reddot {
				top: 0;
				left: -20px;
			}
		}
	}
}

@media (max-width: 991px) {
	.made-in-reddot {
		top: 20px;
		left: 0;
	}
	.productlist {
		.product-thumb {
			.made-in-reddot {
				top: 0px;
				left: -5px;
				width: 130px;
			}
			.made-in {
				width: 52px;
			}
		}
	}
}

@media (min-width: 768px) {
	.box .box-content .last {
		clear: left;
	}
	.fancybox-next {
		right: -30px;
	}
	.fancybox-prev {
		left: -30px;
	}
	.product-reviews .fa-stack {
		font-size: 14px;
	}
}

@media (max-width: 767px) {
	#content {
		.product-layout {
			width: 50%;
			float: left;
			margin-bottom: 30px;
			&:nth-child(2n+3) {
				clear: left;
			}
			+ .product-layout {
				margin-top: 0;
			}
			&.clr{
				clear: both;
			}
		}
		.new-pr {
			top: 5px;
			right: 5px;
		}
	}

	.quickview_description iframe {
		float: none;
		width: 100% !important;
		margin: 0 0 2em;
	}

	#product {
		#button-cart {
			margin: 0 10px 0 0;
		}
	}

	.product-list,
	.product-grid {
		.product-thumb {
			.image, .caption, .aside {
				float: none;
				margin: 0 0 15px;
				width: 100%;
			}
			.aside {
				display: none;
			}
			.caption {
				border-right: none;
			}
			> .price, .cart-button {
				display: block;
			}
		}
	}

	.clearfix + .product-list .product-thumb {
		padding: 0;
	}

	.made-in-reddot {
		top: -60px;
		left: 20px;
		width: 160px;
	}
	.productlist {
		.product-thumb {
			.made-in,
			.made-in-reddot {
				width: 110px;
				left: 4px;
				top: 4px;
			}
			.made-in {
				width: 52px;
			}
		}
	}
	.product_markdown_page {
		#product {
			.product-btn-add {
				margin: 0 !important;
			}
		}
	}
}

@media (max-width: 600px) {
	.quickview_description iframe,
	.product-desc iframe {
		height: 240px !important;
	}
	.made-in-reddot {
		display: none;
	}
}

@media (max-width: 540px) {
	#content .product-layout {
		/*	width: 100%;
			float: none;*/
	}
	.product-search  .product-layout {
		padding: 0;
	}
	.product-thumb .product-options {
		max-width: 95%;
		[class*="col-xs"] {
			float: left;
			+ [class*="col-xs"] {
				margin-top: 0 !important;
			}
		}
	}
}

@media (max-width: 375px) {
	.made-in {
		top: 0;
		width: 50px;
	}
	#logo {
		svg {
			max-width: 120px;
		}
	}
	.product_markdown_page {
		#product {
			.product-btn-add {
				max-width: 100%;
				width: 100%;
			}
		}
		.wrapper-btn-credit {
			flex-direction: column;
		}
		.compare-btn {
			display: none;
		}
	}
}