footer {	
	#footerlogo {
		font-size: 11px;
		font-size: 11.8px;
		img {			
			image-rendering: -webkit-optimize-contrast; /* Chrome (and Safari) */
			image-rendering: optimize-contrast;         /* CSS3 Proposed       */
			-ms-interpolation-mode: nearest-neighbor;   /* IE8+                */
			max-width: 155px;
		}
	}
	.footerlogo-caption {
		display: block;
		font-size: 12px;
	}
	@include box-shadow(0 0 10px rgb(228, 228, 228));
	.footer-heading {
		font-size: 19px;
		font-weight: 500;
		margin-bottom: 10px;
		border-bottom: 2px $primary solid;
		padding-bottom: 5px;
		color: $primary;
	}
	.footer-links {
		font-size: 14px;
		line-height: 16px;
	}
	background: #fff;
	color: $primary;
	padding-top: 30px;
	margin-top: 30px;
	h5{
		font-size: 20px;
		color: $textSecondary;
		+ul{
			margin-top: 15px;
		}
	}
	ul {
		margin-bottom: 0;
		li{
			a, a:visited{
				font-weight: 500;
				color: $primary !important;
				&:hover{
					color: $secondary;
				}
			}
			&+li{
				margin-top: 13px;
			}
		}
	}
	.footer_modules{
		float: right;
	}
	.footer-contacts {
		li {
			margin-top: 10px;
			height: auto;
			font-size: 14px;
			i {
				vertical-align: middle;
				margin-right: 8px;
				font-size: 20px;
			}
			div {
				display: inline-block;
				vertical-align: middle;
				span {
					display: block;
					line-height: 18px;
					vertical-align: middle;
				}
			}
			&.footer-phones span:first-of-type::after {
				content: ', ';
			}
		}		
	}
}

.copyright br{
	display: none;
}

.copyright {
	font-size: 12px;
	margin-top: 34px;
	padding: 0;
	color: #fff;
	text-align: left;
	border-top: 1px solid $borderColor1;
	background: #0264AF;	
	@media (max-width: 767px) { 
		line-height: 15px;
		padding-top: 15px;
	}
	a:hover{
		color: #fff;
	}
	& > .container {
		display: flex;
		gap: 16px;
		justify-content: space-between;
		align-items: center;

		&:before, &:after {
			content: unset;
			display: unset;
		}
	}
	.payments {
		img {
			max-height: 61px;

			&:last-child {
				display: none;
				margin: 8px auto;
			}
		}
		@media (max-width: 767px) {
			position: static;
			width: 100%;
		}
	}
	.oferta {
		margin-bottom: 4px;
		font-size: 12px;
		line-height: 14px;
	}
}
/*========================================================
                    Responsive  Footer
=========================================================*/
@media (min-width: 992px) and (max-width: 1199px) {
	footer{
		.social-list {
			padding-right: 40px;
		}
	}
}
@media (max-width: 991px) {
	footer {
		padding-top: 40px;
		.col-xs-6 {
			margin-top: 30px;
		}
	}
}

@media (min-width: 479px) and (max-width: 767px){
	footer {
		[class*="col-xs"]:nth-child(2n-1){
			clear: both;
		}
		[class*="col-xs"]:nth-child(n+3){
			margin-top: 30px;
		}
	}
}

@media (min-width: 768px) {
	.footer-contacts-block {
		float: right;
	}
}
@media (max-width: 767px) {
	footer {
		padding-top: 0px;
		> .container {
			text-align: left;
		}	
		.footer-logo {
			display: none;
		}		
	}
	.copyright {
		& > .container {
			flex-direction: column;
			gap: 8px;
		}
	}
}

@media (max-width: 480px) {
	footer [class*="col-xs"]{
		float: none;
		width: 100%;
	}
	footer {
		> .container {
			text-align: center;
		}
	}
	.copyright {
		text-align: center;
		.footer_modules{
			float: none;
			margin-top: 15px;
		}
		.payments {
			img {
				&:first-child {
					display: none;
				}
				&:last-child {
					display: block;
				}
			}
		}
	}
}

#blackfriday {
	background: #1c60b5;
	position: fixed;
	bottom: 0;
	width: 100%;
	height: 55px;
	display: flex;
	justify-content: space-around;
	align-items: center;
	font-size: 24px;
	/*font-weight: 500;
	font-family: 'gilroy', sans-serif;*/
	z-index: 1000;
	span.fa {
		position: absolute;
		left: 30px;
		font-size: 30px;
		color: #fff;
		top: 15px;
		&:hover {
			cursor: pointer;
		}
	}
}