/* ===================   Product Quickview   =================== */
.ajax-quickview,
.ajax-quickview-images,
.ajax-quickview-images > ul {
	&>li {
		height: 100%;
	}
}


.ajax-quickview-cont-wrap {
	width: 50%;
}

.ajax-quickview-images {
	position: relative;
	float: left;
	border: none;
	width: 50%;
	padding: 10px 2%;
	text-align: center;
	background: #fff;
	@include border-radius(12px);
	img {
		width: 100%;
		max-width: 330px;
		padding-top: 16%;
	}
	.next-img,
	.prev-img {
		position: absolute;
		top: 50%;
		@include transform(translateY(-50%));
		z-index: 5;
	}
	.next-img {
		right: 15px;
	}
	.prev-img {
		left: 15px;
	}
	.sale, .new-pr {
		top: 20px;
		right: 35px;
	}
}

.ajax-quickview-cont-wrap {
	float: right;
	max-height: 100%;
	overflow-y: auto;
	.ajax-quickview-cont {
		padding: 0px 50px 30px 20px;
		h3 {
			font-size: 26px;
			@media (max-width: 1199px) {
				font-size: 19px;
			}
		}
		.form-group {
			margin-bottom: 5px;
		}
		.cart-button {
			.btn{
				margin-bottom: 5px;
			}
		}
	}
	.sale, .new-pr, .discount {
		position: static;
	}
	.product-options {
		border-bottom: none;
		padding-top: 20px;
		h3{
			text-align: left;
		}
	}
	.product-btn-add {
		vertical-align: middle;
		margin-right: 20px;
	}
	.counter {
		+ .product-btn-add {
			margin-left: 20px;
		}
	}
	.qw-box-carousel {
		.owl-item {
			padding: 5px 5px;
			.product-thumb {
				border: none;
				border-radius: none;
				padding: 10px 10px 0 10px;
				.caption {
					padding: 10px 0;
				}
				.name > a {
					line-height: 13px;
					font-size: 10px; 
				}
			}
		}
		.owl-next, .owl-prev {
			top: 40px;
			opacity: .7 !important;
			i {
				font-size: 20px;
				margin: 0;
			}			
		}
		.owl-next {
			right: -5px;
		}
		.owl-prev {
			left: -5px;
		}
	}
}

.ajax-quickview-overlay {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 20;
	background: rgba($textPrimary, .3);
	opacity: 0;
	visibility: hidden;
	.ajax-quickview-overlay__preloader {
		position: absolute;
		top: 50%;
		left: 50%;
		@include transform(translate(-50%, -50%));
		display: inline-block;
		width: 100px;
		height: 100px;
		background: url(/image/ring.svg) center center no-repeat;
	}
	&.visible {
		opacity: 1;
		visibility: visible;
	}
}

.fancybox-skin{
	@include border-radius(10px);
}

.fancybox-skin:hover .fancybox-nav span {
	visibility: visible;
}

.fancy_holiday_works {
	.fancybox-skin {
		padding: 30px !important;
	}
	.fancybox-inner {
		max-width: 540px !important;
		width: 100% !important;
	}
}

.fancybox-inner {
	height: auto !important;
	max-height: 540px !important;
	width: 900px !important;
	.price-new, .price-old, .price {
		margin-top: 10px;
	}
	@media (max-width: 1070px) {
		width: 700px !important;
	}
}
.qw-box-carousel:not(.owl-carousel) {
	display: none;
}
.fancybox-nav {
	height: 40px;
	top: 50%;
	width: 40px
}

.fancybox-next {
	right: -60px;
}

.fancybox-prev {
	left: -60px;
}

.ajax-quickview {
	max-width: 900px !important;
}

@media (max-width: 1199px) {

}

@media (max-width: 991px) {

}

@media (min-width: 768px) and (max-width: 991px) {
	.product-option-wrap {
		.form-group{
			.product-btn-add{
				display: block;
				margin-bottom: 5px;
			}
		}
	}
}

@media (max-width: 767px) {
	.ajax-quickview-cont {
		padding: 10px 20px;
	}
	.ajax-quickview-images {
		margin: 0 auto;
	}
	.product-buttons li {
		margin-right: 0;
		.product-btn-add {
			padding: 9px 16px;
			font-size: 14px;
		}
	}
	#button-cart {
		margin-right: 10px;
	}
	#button-cart-credit,#button-cart-credit-qv {
		margin: 0;
	}
}
