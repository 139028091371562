/*
* @subsection   RD Parallax
*
* @description  Describes style declarations for RD Parallax extension
*
* @author       Evgeniy Gusarov
* @link         https://ua.linkedin.com/pub/evgeniy-gusarov/8a/a40/54a
* @version      3.0.0
*/
.rd-parallax{

	&-inner{
		overflow: hidden;
		position: relative;
		height: 415px;
  		background-color: $secondary;
  		background-image: linear-gradient(to top, $secondaryDarkest, $secondary);
  		-webkit-backface-visibility: hidden;
  		z-index: 1;
	}

	&-layer[data-type="media"]{
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		height: 100%;
		-webkit-backface-visibility: hidden;

		iframe{
			width: 100%;
			height: 100%;
		}
	}

	&-layer[data-url]{
		-webkit-background-size: cover;
		background-size: cover;
		background-position: center center;
		opacity: .3;
	}

	&-layer[data-type="html"]{
		position: relative;
	}
}