/*========================================================
                      Banners
=========================================================*/
.banners {
	margin-top: 0;
	padding-bottom: 51px;
	position: relative;
	overflow: hidden;
	> div {
		float: left;
		width: 50%;
		position: relative; 
		@include transition(all 0.4s ease);
  		background-color: $secondary;
  		background-image: linear-gradient(to top, darken(desaturate(adjust_hue($secondary, 24.315973571706195), 25.671609714162926%), 1.9607843137254832%), $tetriary);
  		-webkit-backface-visibility: hidden;
  		z-index: 1;
		&:after {
		  	position: absolute;
		  	content: '';
		  	top: 0;
		  	left: 0;
		  	width: 100%;
		  	height: 100%;
		  	background-image: linear-gradient(to top, $secondaryDark, $secondary);
		  	transition: opacity 0.5s ease-out;
		  	z-index: 2;
		  	opacity: 0;
		}	
		.banner-box {
			position: relative;
			z-index: 3;
			text-align: center;
			img{
				@include transition(all 0.5s ease);
				opacity: .5;
			}
			> a {
				display: block;
				img{
					@include transition(all 0.5s ease);
					opacity: .5;
				}
			}
		}
		&:nth-child(even){
	  		background-color: $primaryDark;
  			background-image: linear-gradient(to top, $primaryDark, lighten(saturate(adjust_hue($primaryLight, -13.547497043752458), 11.340206185566998%), 3.3333333333333357%));		
  			&:after {
		  		background-image: linear-gradient(to top, $secondaryDark, $secondary);
			}
		}
		&:hover{
			&:after {
		  		opacity: 1;
			}
			.banner-box {
				img{
					opacity: .9;
				}
				> a {
					img{
						opacity: .9;
					}
				}				
			}
		}
	}
}

.banners > div .s-desc {
	position: absolute;
	text-align: center;
	left: 30px;
	right: 30px;
	top: 50%;
	@include transform(translateY(-50%));
	h3{
		font-size: 30px;
		line-height: 43px;
		text-transform: none;
		font-weight: 400;
		color: #fff;
		margin-bottom: 0;
	}
	.btn {
		margin-top: 30px;
		padding: 17px 30px;
		background: transparent;
		&:before{
			border: 2px solid #fff;
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			border-radius: 27px;
			display: inline-block;
			content: "";
		}
		&:after {
		  	background-image: linear-gradient(to top, darken(desaturate(adjust_hue($secondary, 24.315973571706195), 25.671609714162926%), 1.9607843137254832%), $tetriary);
		}
	}
}

.banners > div:nth-child(odd) .s-desc .btn {
	&:after {
		background-image: linear-gradient(to top, $secondaryDark, $secondary);
	}
}

/*========================================================
                   Responsive Banners
=========================================================*/


@media (max-width: 1199px) {

	.banners {
		margin-top: -1px;
		padding-bottom: 28px;
		> div .s-desc {
			h3{
    			font-size: 28px;
    			line-height: 35px;
			}
			.btn {
				letter-spacing: 0;
				padding: 10px 20px;
				font-size: 15px;
				margin-top: 25px;
			}
		}
	}

}

@media (min-width: 768px) and (max-width: 991px) {

	.banners > div .s-desc {
		h3{
    		font-size: 21px;
    		line-height: 24px;
		}
		.btn {
			margin-top: 15px;
		}
	}

}

@media (max-width: 767px) {

	.banners {
		padding-bottom: 50px;
		> div {
			width: 100%;
			.s-desc {
				top: 50%;
				@include transform(translateY(-50%));
			}
		}
	}

}

@media (max-width: 480px) {

	.banners > div .s-desc {
		h3{
    		font-size: 16px;
    		line-height: 20px;
		}
		.btn {
			margin-top: 10px;
			font-size: 14px;
			padding: 5px 15px;
		}    	
	}
	
}