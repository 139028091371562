/*========================================================
                      Form Styles
=========================================================*/
//Input
$inputBackground: #fff;
$inputColor: #777;
$inputBorderColor: #f1f1f1;
$inputBorderFocus: 0 0 7px $secondary inset;
$inputHeight: 50px;
$inputFontSize: 16px;
$inputLineHeight: 24px;
$inputBorderRadius: 5px;
$inputPadding: (($inputHeight - $inputLineHeight)/2) 10px (($inputHeight - $inputLineHeight)/2) 20px;

//Select Arrow
$select-arrow-width: 50px;
$select-arrow-icon: '\e071';

// Radio
$radio-size: 16px;
$radio-border: 1px solid $borderColor;
$radio-background: $stockBg;

$radio-inner-size: 12px;
$radio-inner-bg: $primary;

//Checkbox
$checkbox-size: 14px;
$checkbox-bg: #e5e5e5;
$checkbox-border: none;
$checkbox-border-radius: 5px;

$checkbox-icon: '\f00c';
$checkbox-icon-color: $primary;

//==============================
// Input  Styles
select,
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"] {
	display: block;
	width: 100%;
	background: #fff;
	border: 1px solid $inputBorderColor;
	color: $inputColor;
	font-size: $inputFontSize;
	height: $inputHeight;
	line-height: $inputLineHeight;
	padding: $inputPadding;
	outline: none;
	@include box-sizing(border-box);
	@include border-radius(5px);
	@include transition(.3s all ease);
	@include box-shadow(none);
	-webkit-appearance: none;
	-moz-appearance:    none;
	appearance:         none;
	&:focus{
		@include box-shadow(1px 1px 10px $inputBorderFocus);
	}
	&::-moz-placeholder {
		color: $inputColor!important;
	}
	&:focus::-moz-placeholder {
		color: transparent!important;
	}
}
.product-options {
	.checkbox input[type="checkbox"],
	.radio input[type="radio"] {
		+ label {
			border: 1px solid #ccc;
			color: #777;
			@include border-radius(5px);
		}
	}
}
select:hover{
	cursor: pointer;
}

input[name*="quantity"] {
	display: inline-block;
	width: 40px;
	text-align: center;
	padding-left: 5px;
	padding-right: 5px;
	vertical-align: middle;

}

input[name="captcha"] {
	+ img {
		margin-top: 15px;
	}
}

textarea:not([class*="habla"]) {
	min-height: 200px;
}

select {
	display: none;
}

#modal-shipping {
	input[type="radio"] {
		display: inline;
		float: left;
		margin-top: 8px;
	}
	@media (max-width: 479px) {
		.btn, #button-shipping {
			margin-top: 5px;
		}
	}
}

.counter, .counter:focus{
	display: inline-block;
	height: $inputHeight;
	width: $inputHeight;
	color: $inputColor;
	background: #fff;
	line-height: $inputHeight;
	text-align: center;
	font-size: 20px;
	border: 1px solid #f1f1f1;
	@include border-radius(5px);
	vertical-align: middle;
	&:hover{
		background: $primary;
		color: $textSecondary;
	}
	@media (max-width: 991px) {
		width: 36px;
	}
	@media (max-width: 767px) {
		display: none;
	}
}

#product .counter{
	@media (max-width: 767px){
		display: inline-block;
	}
}

.input-group-btn .btn {
	height: $inputHeight;
	padding-top: 0;
	padding-bottom: 0;
	@include box-sizing(border-box);
}

.product-options{
	.form-group.required {
		input{
			@include border-radius(5px 0 0 5px);
		}
		.btn.btn-default{
			&:after{
				@include border-radius(0 25px 25px 0);
			}
			.glyphicon{
				top: 3px;
			}
		}
		.sbHolder{
			@include border-radius(5px);
			.sbSelector{
				right: $select-arrow-width;
				@include border-radius(5px 0 0 5px);
			}
		}
	}
}

//==============================
// Select Styles

.sbHolder {
	border: 1px solid #f1f1f1;
	height: $inputHeight;
	position: relative;
	width: 190px;
	text-align: left;
	color: $inputColor;
	background: #fff;
	@include border-radius($inputBorderRadius);
	@include box-sizing(border-box);
	min-width: 120px;
}

.sbSelector {
	display: block;
	height: 100%;
	line-height: $inputLineHeight;
	position: absolute;
	left: 0;
	top: 0;
	right: $select-arrow-width;
	padding: (($inputHeight - $inputLineHeight)/2) 10px (($inputHeight - $inputLineHeight)/2) 15px;
	overflow: hidden;
	white-space: nowrap;
	-ms-text-overflow: ellipsis;
	text-overflow: ellipsis;
	@include box-sizing(border-box);
}

//==============================
// Select Arrow Styles

.sbToggle {
	display: block;
	height: 100%;
	outline: none;
	position: absolute;
	right: 0;
	top: 0;
	width: $select-arrow-width;
	text-align: center;
	color: inherit;
	font-family: $material;
	line-height: $inputHeight;
	font-size: 28px;
	&:before {
		content: $select-arrow-icon;
		display: block;
		@include transition(.5s all ease);
	}
	&:hover {
		color: inherit;
	}
	&Open {
		&:before {
			@include transform(rotateX(180deg));
		}
	}
}

.sbHolderDisabled {
	background-color: #3C3C3C;
	border: solid 1px #ccc;
}

/*==============================
 Select Dropdown Styles*/

.sbOptions {
	background-color: $stockBg;
	list-style: none;
	left: 0;
	margin: 1px 0;
	padding: 10px 0;
	position: absolute;
	right: 0;
	z-index: 4;
	overflow-y: auto;
	@include box-shadow($baseBoxShadow);
	outline: 1px solid $borderColor;
	li {
		a {
			padding: 2px 10px;
			font-size: 13px;
			color: $inputColor;
			&:hover {
				background: #6e9ecf;
				color: $textSecondary;
			}
		}
	}
}

//==============================
// Radio Styles
input[type="radio"] {
	display: none;
}

input[type="radio"] + label {
	position: relative;
	padding-left: 30px;
	line-height: $baseLineHeight;
	&:before, &:after {
		content: '';
		position: absolute;
		@include border-radius(50%);
		@include box-sizing(border-box);
	}
	&:before {
		top: ($baseLineHeight - $radio-size)/2;
		left: 0;
		width: $radio-size;
		height: $radio-size;
		border: 1px solid #ccc;
		background: $radio-background;
	}
	&:after {
		top: (($radio-size - $radio-inner-size)/2) + ($baseLineHeight - $radio-size)/2;
		left: (($radio-size - $radio-inner-size)/2);
		width: $radio-inner-size;
		height: $radio-inner-size;
		@include transition(.3s all ease);
	}
}

input[type="radio"]:checked + label:after {
	background: $radio-inner-bg;
}

.radio-inline {
	margin-right: 5px;
}

//==============================
// Checkbox Styles
input[type="checkbox"] {
	display: none;
}

input[type="checkbox"] + label {
	position: relative;
	padding-left: 20px;
	cursor: pointer;
	&:before, &:after {
		content: '';
		position: absolute;
		@include box-sizing(border-box);
		top: ($baseLineHeight - $checkbox-size)/2;
		left: 0;
		width: $checkbox-size;
		height: $checkbox-size;
	}
	&:before {
		background: $checkbox-bg;
		border: $checkbox-border;
		@include border-radius($checkbox-border-radius);
	}
	&:after {
		text-align: center;
		line-height: $checkbox-size;
		font-family: $fa;
		color: $checkbox-icon-color;
	}
}

input[type="checkbox"]:checked + label:after {
	content: $checkbox-icon;
}

input[type="checkbox"][disabled] + label {
	color: lighten($baseColor,30%);
	display: none;
}

input[type="checkbox"][name="agree"] + label{
	margin-bottom: $baseLineHeight/2;
	&:before,&:after{
		top: 0;
	}
}
input[type="checkbox"][name="agree_news_stock"] + label{
	margin-bottom: $baseLineHeight/2;
	&:before,&:after{
		top: 0;
	}
}


#habla_window_div #habla_topbar_div {
	background: $olark-heading-bg !important;
}

#at15s, #at20mc {
	z-index: 999 !important;
}

#product label {
	color: $inputColor!important;
}
