/*--------------------------------------------------------------
                    NewsLetter Popup Module
--------------------------------------------------------------*/

#tm-newsletter-popup-form{
	@include clearfix;
}
.newsletter-popup-wrap{
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 10000;
	padding-top: 320px;
	background: rgba(0,0,0,.1);
	@media (max-width: 991px) {
		padding-top: 192px;
	}
}

.newsletter-popup{
	position: relative;
	@include clearfix;
	width: 745px;
	margin: 0 auto;
	background-color: $headerBg;
	background-repeat: no-repeat;
	border-radius: 0;
	padding: 80px 50px 64px;
	text-align: center;

	h2{
		font: 400 30px/43px $baseFontFamily;
		color: #fff;
		margin: 0;
		+p{
			margin: 6px 0 0;
			font-size: 16px;
			line-height: 26px;
			font-weight: 300;
			color: #fff
		}
	}


	.tm-login-form{
		position: relative;
		padding: 0;
		width: 408px;
		margin: 32px auto;
		input{
			background: transparent;
			position: relative;
			border-radius: 27px;
			border: 2px solid $borderColor1;
			color: #fff;
			width: 100%;
			font-size: 16px;
			height: 54px;
			font-weight: 300;
			padding: 14px 168px 14px 20px;
			@include box-sizing(border-box);
			&::-webkit-input-placeholder {color: #fff!important;}
			&::-moz-placeholder          {color: #fff!important;}
			&:-moz-placeholder           {color: #fff!important;}
			&:-ms-input-placeholder      {color: #fff!important;}

			&:focus::-webkit-input-placeholder {color: transparent!important;}
			&:focus::-moz-placeholder          {color: transparent!important;}
			&:focus:-moz-placeholder           {color: transparent!important;}
			&:focus:-ms-input-placeholder      {color: transparent!important;}
			&:focus {
				box-shadow: $inputBorderFocus;
			}
		}
		.btn{
			position: absolute;
			right: 0;
			top: 0;
			padding-left: 30px;
			padding-right: 30px;
		}
	}

	.logo+h2{
		margin-top: 25px;
	}

	.logo img{
		display: inline-block;
	}

	&-close-btn{
		background: none;
		outline: none;
		border: none;
		position: absolute;
		color: #fff;
		font-size: 30px;
		right: 27px;
		top: 23px;
		line-height: 31px;
		&:hover{
			color: $primary;
		}
	}
}


.newsletter-popup .newsletter-error,
.newsletter-popup .newsletter-success{
	display: block;
	text-align: left;
	margin-top: 5px;
	padding: 0 22px;
	font-size: 14px;
	line-height: 20px;
	font-weight: 300;
	position: absolute;
	border-radius: 0;
	background: none;
	top: 100%;
	@include transition(.3s all ease);
}

.newsletter-error{
	color: $tetriary;
}
.newsletter-success{
	color: $primaryLight;
}
.newsletter-popup-dont-show{
	display: none;
}

/*--------------------------------------------------------------
         NewsLetter Popup Module Responsive Layout
--------------------------------------------------------------*/
@media (max-width: 991px){
	.newsletter-popup .tm-login-form .btn{
		padding: 16px 23px 17px;
	}
}
@media (max-width: 767px){
	.newsletter-popup-wrap{
		display: none !important;
	}
}