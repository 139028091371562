/*================ Box Cart  ===============*/
$close-button-color: #fff;

.box-cart {
	position: relative;
	float: right;
	.btn-danger,.btn-danger:focus {
		border: 0;
		padding: 0;
		color: $primary;
		font-size: 13px;
		background: none;
		&:hover {
			color: #fff;
			background: none;
		}
		&:focus {
			box-shadow: none;
		}
	}
	a:hover img {
		opacity: .5;
	}
	.btn {
		margin-bottom: 7px;
	}
}

/*================  BUY  ===============*/
.buy_products {
	.column-price {
		text-align: right;
	}
	.column-price, .column-total {
		padding-bottom: 18px;
	}
	h5 {
		font-family: 'Roboto', sans-serif;
		margin: 0;
	}
	.quantity {
		width: 65px;
		display: inline-block;
		margin-right: 10px;
	}
	.upddel {
		display: inline-block;
		button {
			padding: 8px 11px;
			@include border-radius(10px);
			@include transition(all 0.4s ease);
			display: inline-block;
			color: #fff;
			&.btn-update {				
				background-color: $primary;
				background-image: linear-gradient(to top, $primary, $primaryLight);
			}
			&.btn-remove {
				background-color: #0264af;			
			}
		}
	}
}
.advantages .img {
	padding: 0 10px;
}


/*================  Cart  ===============*/
.cart {
	position: relative;
	padding: 0;
	line-height: 31px;
	.btn-remove {
		&-wrap, &__cnt {
			position: absolute;
			left: 0;
			right: 0;
			text-align: center;
			.btn.btn-danger.btn-xs {
				padding: 10px;
				font-size: 14px;
				line-height: 18px;
				display: block;
				margin-left: 20px;
				&:before{
					border: 1px solid #fff;
					position: absolute;
					left: 0;
					top: 0;
					right: 0;
					bottom: 0;
					border-radius: 27px;
					display: inline-block;
					content: "";
				}
				&:visited, 
					&:focus {
					color: #fff;
					outline: none;
				}
				&:hover{
					color: #fff;
				}
			}
		}
		&-wrap {
			top: 0;
			bottom: 0;
			z-index: 10;
			padding: 10px;
			background: #494753;
			display: none;
			height: 100%;
		}
		&__cnt {
			top: 50%;
			@include transform(translateY(-50%));
			button{
				i{
					display: none;
				}
			}
		}
	}	
	> button {
		font-weight: 500px;
		font-size: 16px;
		i,strong,span{
			vertical-align: middle;
			font-weight: 500;
			letter-spacing: 1px;
			color: $primary;
			@include transition(all 0.4s ease);
		}
		i{
			font-size: 30px;
			position: relative;
			margin: 0;
			top: 0;
		}
		&:hover,&.active{
			strong, span, i{
				color: $secondary;
			}
		}
	}
	#cart-price {
		color: $primary;
		margin: 0;
		font-size: 13px;
		line-height: 19px;
	}
	strong, b {
		color: #fff;
	}
}
.header_phones {
	width: 40% !important;
	position: relative;
	.holiday_works {
		border: none;
		position: absolute;
		left: 68px;
		bottom: -19px;
		z-index: 999;
		@media (max-width: 991px) {
			left: auto;
			bottom: 2px;
		}
		@media (max-width: 767px) {
			right: 10px;
			top: 37px;
		}
		@media (max-width: 562px) {
			display: none;
		}
	}
}
.header_cart {
	width: 18% ;
	.box-cart {
		margin-top: 10px;
	}
}
@media (max-width: 1199px) {
	.box-cart{
		margin-left: 10px !important;
	}
}
@media (max-width: 991px) {
	.cart {
		.toggle_cont {
			left: -85px;
		}
	}
	.box-cart{
		margin-top: 10px !important;
	}
	#cart-price, #compare-total {
		font-size: 11px !important;
	}
	#checkout-form .advantages {
		.col-sm-12 {
			display: flex;
			padding: 7px 0;
			>div {
				-webkit-box-align: center;
				-ms-flex-align: center;
				align-items: center;
			}
		}
		img {
			height: auto !important;
		}
	}
}
@media (max-width: 767px) {	
	.header_phones {
		width: 50% !important;
	}
	.cart, .compare-widget {
		position: fixed;
		top: 0;
		right: 46px;
		margin: 0;
		padding: 9px 0 4px;
		z-index: 10000;
		i{
			font-size: 30px;
			margin: 0 3px 0 0;
		}
		.compare-total2{
			position: absolute;
			top: 5px;
			right: -14px;
			@media (max-width: 480px) {	
				right: 0;
			}
			width: 30px;
			height: 30px;
			line-height: 24px;
			text-align: center;
			font-size: 15px;
			background: transparent;
			border: 2px solid #fff;
			color: $textSecondary;
			@include border-radius(50%);
		}
		> button {
			position: relative;
			padding-right: 17px;
			line-height: 40px;
			.cart-total,strong{
				display: none;
			}
			.cart-total2, .badge{
				position: absolute;
				top: 3px;
				right: -14px;
				@media (max-width: 480px) {	
					right: 0;
				}
				width: 30px;
				height: 30px;
				line-height: 24px;
				text-align: center;
				font-size: 15px;
				background: transparent;
				border: 2px solid #fff;
				color: $textSecondary;
				@include border-radius(50%);
			}
			&:hover,
				&.active{
				.cart-total2{
					color: $textSecondary;
					border-color: $tetriary;
				}
			}
		}
		.toggle_cont {
			max-height: 300px;
			overflow: auto;
			right: -30px;
			left: auto;
		}
	}
	.compare-widget {
		z-index: 10003;
		right: 130px;
		top: 3px;
		i {
			color: #fff;
		}
		.compare-total2{
			top: 9px;
			right: -30px;
			padding: 0px;
		}
	}
	.table-responsive-custom {
		background: #fff;
		&> thead {
			display: none;
		}
		.img-thumbnail {
			max-width: 160px;
		}
		td{
			position: relative;
			padding-left: 43% !important;
			text-align: left  !important;
			white-space: normal !important;
			&.image-col {
				width: 100%;
				text-align: center !important;
			}
			&:first-child {
				border-top: 0;
			} 
			div.th-title {
				display: block;
				position: absolute;
				left: 10px;
				width: 38%;
				padding-right: 10px;
				text-align: right;
				top: 50%;				
				@include transform(translateY(-50%));
				font-weight: bold;
				text-transform: uppercase;
			}
		}
		&,tbody,td,tr {
			display: block;
			border: 0;
			width: 100%;
		}
		.input-group-qty {
			display: inline-block;
			padding-right: 10px;
		}
		.shopping-cart-btns {
			text-align: center;
			.btn.pull-right, .btn.pull-left{
				float: none !important;
				margin: 0 !important;
			}
		}
		.checkout-buttons {
			margin: 0; 
			div {
				display: block;
				text-align: center;
				float: none !important;
				margin: 10px;
			}
		}
	}

	.total-table{ 
		.text-right {
			padding-left: 60% !important;
		}
		td {
			div.th-title {
				width: 53% !important;
			}
			&.no-title {
				padding-left: 20px !important;
				text-align: center !important;
			}

		}
	}


}
@media (max-width: 640px) {
	.cart {
		.toggle_cont {
			max-height: 224px;
		}
	}
}

@media (max-width: 480px) {
	.cart {
		right: 10px;
		> button {
			padding-right: 30px;
		}
	}	
	.compare-widget {
		right: 110px;
	}
}