/*========================================================
                         Box
=========================================================*/
.box {
	margin-bottom: 30px;
	.box-heading {
		padding: 10px 0;
		margin: 0 0 30px;
		h3 {
			margin: 0;
			font: 700 16px/30px $baseFontFamily;
		}
	}
	@media (max-width: 767px)  {
		margin-bottom: 0px;
		.owl-item {
			padding: 0px 15px;
		}
	}
}

aside .box  {
	.box-heading{
		margin-bottom: 20px;
		padding-bottom: 21px;
		text-transform: uppercase;
	}
	.product-layout {
		width: 100%;
		+ .product-layout {
			margin-top: 0px;
		}
	}
}

aside{
	[class*="col-"],.container{
		width: 100%;
		padding: 0;
	}
	.row{
		margin: 0;
	}
}

/*========================================================
                    Responsive Box
=========================================================*/
@media (min-width: 768px) and (max-width: 991px) {
	.box.info .box-content ul a {
		padding: 13px 5px;
	}
	aside .box .box-heading h3{
		font-size: 16px;
	}
}

@media (max-width: 767px) {
	aside .box .box-heading {
		position: relative;
		cursor: pointer;
		padding-bottom: 11px;
		margin-bottom: 35px;
		border: 1px solid #ddd;
		@include border-radius(15px);
		h3{
			text-align: left;
			padding: 0 30px;
		}
		&:after {
			content: '\f055';
			position: absolute;
			right: 10px;
			top: 14px;
			font-size: 21px;
			font-family: $fa;
			color: $textPrimary;
		}
		&.active {
			&:after {
				content: '\f056';
			}
		}
	}
}

@media (min-width: 550px) and (max-width: 767px) {
	aside .box .product-thumb{
		width: 60%;
		margin: 0 auto 30px;
	}
}
