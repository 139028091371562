#page-preloader {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	background: $dropdown-bg;
	z-index: -1;
	opacity: 0;
	@include transition(all 1.3s ease .3s);
	&.visible {
		opacity: 1;
		z-index: 100500;
		.preloader {
			opacity: 1;
			@include transform(translate(-50%, -50%));
		}
	}
	.preloader {
		width: 180px;
		height: 40px;
		position: absolute;
		left: 50%;
		top: 50%;
		background: url('../image/preloader.gif') no-repeat 50% 50%;
		@include transform(translate(-50%, -50%));
		@include transition(all .3s ease);
		opacity: 0;
	}
}