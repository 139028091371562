/*--------------------------------------------------------------
                    NewsLetter Block
--------------------------------------------------------------*/
.newsletter{
	.box-heading {
		padding: 0;
		margin: 0 0 20px;
		h3{
			color: #fff;
			font-size: 30px;
			line-height: 43px;
			margin-bottom: 10px;
			text-transform: none;
			font-weight: 400;
		}
	}
	.box-content{
		padding: 0;
		.newsletter-description{
			font-size: 16px;
   			margin-bottom: 30px;
   			font-weight: 400;
   			color: #fff;
   			text-align: center;
		}
		#tm-newsletter-form{
			position: relative;
			border-radius: 15px;
			
		}
		.tm-login-form{
			border-radius: 27px;
			width: 460px;
			margin: 0 auto;
			position: relative;
			background: transparent;
			input{
				border-radius: 27px;
				width: 65%;
				height: 54px;
				float: left;
				border: 2px solid #fff;
				color: #fff;
				font-size: 16px;
				background: $headerBg;
				padding: 13px 45px 13px 22px;
				&::-moz-placeholder {
					color: #fff!important;
				}
				&:focus {
					box-shadow: $inputBorderFocus;
				}
				&:focus::-moz-placeholder {
					color: transparent!important;
				}
			}
			.btn{
				float: left;
				width: 35%;
				margin: 0;
				left: -47px;
				padding-left: 10px;
				padding-right: 10px;
				background-image: linear-gradient(to top, $secondaryDark, $secondary);
				&:before{
					border: 2px solid #fff;
					position: absolute;
					left: 0;
					top: 0;
					right: 0;
					bottom: 0;
					border-radius: 27px;
					display: inline-block;
					content: "";
				}
				&:after {
				  	background-image: linear-gradient(to top, darken(desaturate(adjust_hue($secondary, 24.315973571706195), 25.671609714162926%), 1.9607843137254832%), $tetriary);
				}
			}	
			.newsletter-error,
			.newsletter-success{
				position: absolute;
				font-size: 14px;
				line-height: 20px;
				color: #fff;
				top: 55px;
				left: 14px;
				right: 0;
				text-align: left;
				@include transition(.4s all ease);
			}
			.newsletter-error{
				color: $tetriary;
			}
			.newsletter-success{
				color: #55C65E;
}		
		}
	}
}


/*--------------------------------------------------------------
           NewsLetter Block Responsive Layout
--------------------------------------------------------------*/


@media (max-width: 1199px) {
	.newsletter .box-content .tm-login-form .btn{
		padding: 15px 21px 18px;
	}
}

@media (max-width: 640px) {
	.newsletter{
		.box-content{
			.tm-login-form{
				width: 100%;
				float: none;
				input{
					padding: 13px 20px;
					text-align: center;
					width: 100%;
				}
				.btn{
					width: 100%;
					left: 0;
					margin-top: 10px;
					padding: 15px 20px 16px;
				}
				.newsletter-error, 
				.newsletter-success{
					top: 125px;
					text-align: center;
				}
			}
		}
	}
}