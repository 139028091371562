/**
* Swiper 3.3.1
* Most modern mobile touch slider and framework with hardware accelerated transitions
* 
* http://www.idangero.us/swiper/
* 
* Copyright 2016, Vladimir Kharlampidi
* The iDangero.us
* http://www.idangero.us/
* 
* Licensed under MIT
* 
* Released on: February 7, 2016
*/
.swiper-container {
	margin: 0 auto;
	position: relative;
	overflow: hidden;
	z-index: 1;
}
.swiper-container-no-flexbox .swiper-slide {
	float: left;
}
.swiper-container-vertical > .swiper-wrapper {
	-webkit-box-orient: vertical;
	-moz-box-orient: vertical;
	-ms-flex-direction: column;
	-webkit-flex-direction: column;
	flex-direction: column;
}
.swiper-wrapper {
	position: relative;
	width: 100%;
	height: 100%;
	z-index: 1;
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-transition-property: -webkit-transform;
	-moz-transition-property: -moz-transform;
	-o-transition-property: -o-transform;
	-ms-transition-property: -ms-transform;
	transition-property: transform;
	-webkit-box-sizing: content-box;
	-moz-box-sizing: content-box;
	box-sizing: content-box;
}
.swiper-container-android .swiper-slide,
.swiper-wrapper {
	-webkit-transform: translate3d(0px, 0, 0);
	-moz-transform: translate3d(0px, 0, 0);
	-o-transform: translate(0px, 0px);
	-ms-transform: translate3d(0px, 0, 0);
	transform: translate3d(0px, 0, 0);
}
.swiper-container-multirow > .swiper-wrapper {
	-webkit-box-lines: multiple;
	-moz-box-lines: multiple;
	-ms-flex-wrap: wrap;
	-webkit-flex-wrap: wrap;
	flex-wrap: wrap;
}
.swiper-container-free-mode > .swiper-wrapper {
	-webkit-transition-timing-function: ease-out;
	-moz-transition-timing-function: ease-out;
	-ms-transition-timing-function: ease-out;
	-o-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
	margin: 0 auto;
}
.swiper-slide {
	-webkit-flex-shrink: 0;
	-ms-flex: 0 0 auto;
	flex-shrink: 0;
	width: 100%;
	height: 100%;
	position: relative;
	.caption{
		display:inline-block;
		position: absolute;
		top: 55%;
		right: 0;
		bottom: 0;
		left: 0;
		text-align: center;
  		vertical-align: middle;
  		@include transform(translateY(-43%));
		h2{
			color: #fff;
			font-size: 96px;
			line-height: 76px;
			letter-spacing: 9px;
			text-transform: uppercase;
			margin: 0 0 24px;
		}
		h6{
			color: #fff;
			font-size: 30px;
			line-height: 43px;
			font-weight: 400;
			text-transform: none;
			margin: 0 0 39px;				
		}
		.btn{
			padding: 16px 32px 18px;
			background: transparent;
			&:before{
				border: 2px solid #fff;
				position: absolute;
				left: 0;
				top: 0;
				right: 0;
				bottom: 0;
				border-radius: 27px;
				display: inline-block;
				content: "";
			}
			&:after{
				background-image: linear-gradient(to top, $primaryDark, lighten(saturate(adjust_hue($primaryLight, -13.547497043752458), 11.340206185566998%), 3.3333333333333357%));
			}			
		}
	}
}
.swiper-container-autoheight,
.swiper-container-autoheight .swiper-slide {
	height: auto;
}
.swiper-container-autoheight .swiper-wrapper {
	-webkit-box-align: start;
	-ms-flex-align: start;
	-webkit-align-items: flex-start;
	align-items: flex-start;
	-webkit-transition-property: -webkit-transform, height;
	-moz-transition-property: -moz-transform;
	-o-transition-property: -o-transform;
	-ms-transition-property: -ms-transform;
	transition-property: transform, height;
}
.swiper-container .swiper-notification {
	position: absolute;
	left: 0;
	top: 0;
	pointer-events: none;
	opacity: 0;
	z-index: -1000;
}
.swiper-wp8-horizontal {
	-ms-touch-action: pan-y;
	touch-action: pan-y;
}
.swiper-wp8-vertical {
	-ms-touch-action: pan-x;
	touch-action: pan-x;
}
.mobile, .tablet{
  .preview{
    display: none;
  }
}
.swiper-button {
  -webkit-perspective: 1000px;
  perspective: 1000px;
  position: absolute;
  top: 50%;
  @include transform(translateY(-50%));
  z-index: 9;
  cursor: pointer;
  &.swiper-button-next {
    right: 30px;
    -webkit-perspective-origin: 0% 50%;
    perspective-origin: 0% 50%;
    .preview__img {
      right: 0;
      @include transform(translateX(30px));
    }
  }
  &.swiper-button-prev {
    left: 30px;
    -webkit-perspective-origin: 100% 50%;
    perspective-origin: 100% 50%;
    .preview__img {
      left: 0;
      @include transform(translateX(-30px));
    }
  }
  &.swiper-button-next,
  &.swiper-button-prev {
    i{
      position: absolute;
      line-height: 54px;
      @include transition(all 0.4s ease);
      color: #fff;
      font-size: 35px;
      text-align: center;
      left: 0;
      right: 0;
    }
  }

  .swiper-button__arrow{
    display: block;
    width: 56px;
    height: 56px;
    border: 2px solid #fff;
    @include border-radius(50%);
    background-color: transparent;
    @include transition(all 0.4s ease);
  }
  .preview__img {
    position: absolute;
    top: 0;
    bottom: 0;
    opacity: 0;
    min-width: 267px;
    z-index: -1;
    -webkit-background-size: cover;
    background-size: cover;
    @include transition(all 0.6s ease);
    @include border-radius(45px);
    backface-visibility: hidden;
    @include transition-delay(.3s);
  }
  &:hover{
    .swiper-button__arrow{
        width: 91px;
    	height: 91px;
    	i{
    		line-height: 91px;
    	}
    }
  }
}

.desktop{
  .swiper-button {
    &:hover{
      .preview__img {
      	opacity: 1;
        @include transform(translateX(0%));
      }
    }
  }
}
.swiper-pagination {
	position: absolute;
	text-align: center;
	-webkit-transition: 300ms;
	-moz-transition: 300ms;
	-o-transition: 300ms;
	transition: 300ms;
	-webkit-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	-o-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	z-index: 10;
}
.swiper-pagination.swiper-pagination-hidden {
	opacity: 0;
}
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
	bottom: 15px;
	left: 0;
	width: 100%;
}
.swiper-pagination-bullet {
	width: 12px;
	height: 12px;
	display: inline-block;
	border-radius: 100%;
	background: #000;
	opacity: 0.2;
}
button.swiper-pagination-bullet {
	border: none;
	margin: 0;
	padding: 0;
	box-shadow: none;
	-moz-appearance: none;
	-ms-appearance: none;
	-webkit-appearance: none;
	appearance: none;
}
.swiper-pagination-clickable .swiper-pagination-bullet {
	cursor: pointer;
}
.swiper-pagination-white .swiper-pagination-bullet {
	background: #fff;
}
.swiper-pagination-bullet-active {
	opacity: 1;
	background: #007aff;
}
.swiper-pagination-white .swiper-pagination-bullet-active {
	background: #fff;
}
.swiper-pagination-black .swiper-pagination-bullet-active {
	background: #000;
}
.swiper-container-vertical > .swiper-pagination-bullets {
	right: 10px;
	top: 50%;
	-webkit-transform: translate3d(0px, -50%, 0);
	-moz-transform: translate3d(0px, -50%, 0);
	-o-transform: translate(0px, -50%);
	-ms-transform: translate3d(0px, -50%, 0);
	transform: translate3d(0px, -50%, 0);
}
.swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
	margin: 5px 0;
	display: block;
}
.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
	margin: 0 5px;
}
.swiper-pagination-progress {
	background: rgba(0, 0, 0, 0.25);
	position: absolute;
}
.swiper-pagination-progress .swiper-pagination-progressbar {
	background: #007aff;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	-webkit-transform: scale(0);
	-ms-transform: scale(0);
	-o-transform: scale(0);
	transform: scale(0);
	-webkit-transform-origin: left top;
	-moz-transform-origin: left top;
	-ms-transform-origin: left top;
	-o-transform-origin: left top;
	transform-origin: left top;
}
.swiper-container-rtl .swiper-pagination-progress .swiper-pagination-progressbar {
	-webkit-transform-origin: right top;
	-moz-transform-origin: right top;
	-ms-transform-origin: right top;
	-o-transform-origin: right top;
	transform-origin: right top;
}
.swiper-container-horizontal > .swiper-pagination-progress {
	width: 100%;
	height: 4px;
	left: 0;
	top: 0;
}
.swiper-container-vertical > .swiper-pagination-progress {
	width: 4px;
	height: 100%;
	left: 0;
	top: 0;
}
.swiper-pagination-progress.swiper-pagination-white {
	background: rgba(255, 255, 255, 0.5);
}
.swiper-pagination-progress.swiper-pagination-white .swiper-pagination-progressbar {
	background: #fff;
}
.swiper-pagination-progress.swiper-pagination-black .swiper-pagination-progressbar {
	background: #000;
}
.swiper-container-3d {
	-webkit-perspective: 1200px;
	-moz-perspective: 1200px;
	-o-perspective: 1200px;
	perspective: 1200px;
}
.swiper-container-3d .swiper-wrapper,
.swiper-container-3d .swiper-slide,
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-slide-shadow-bottom,
.swiper-container-3d .swiper-cube-shadow {
	-webkit-transform-style: preserve-3d;
	-moz-transform-style: preserve-3d;
	-ms-transform-style: preserve-3d;
	transform-style: preserve-3d;
}
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-slide-shadow-bottom {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;
	z-index: 10;
}
.swiper-container-3d .swiper-slide-shadow-left {
	background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
	background-image: -webkit-linear-gradient(right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
	background-image: -moz-linear-gradient(right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
	background-image: -o-linear-gradient(right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
	background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}
.swiper-container-3d .swiper-slide-shadow-right {
	background-image: -webkit-gradient(linear, right top, left top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
	background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
	background-image: -moz-linear-gradient(left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
	background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
	background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}
.swiper-container-3d .swiper-slide-shadow-top {
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
	background-image: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
	background-image: -moz-linear-gradient(bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
	background-image: -o-linear-gradient(bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
	background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}
.swiper-container-3d .swiper-slide-shadow-bottom {
	background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
	background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
	background-image: -moz-linear-gradient(top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
	background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
	background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}
.swiper-container-coverflow .swiper-wrapper,
.swiper-container-flip .swiper-wrapper {
	-ms-perspective: 1200px;
}
.swiper-container-cube,
.swiper-container-flip {
	overflow: hidden;
}
.swiper-container-cube .swiper-slide,
.swiper-container-flip .swiper-slide {
	pointer-events: none;
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	-ms-backface-visibility: hidden;
	backface-visibility: hidden;
	z-index: 1;
}
.swiper-container-cube .swiper-slide .swiper-slide,
.swiper-container-flip .swiper-slide .swiper-slide {
	pointer-events: none;
}
.swiper-container-cube .swiper-slide-active,
.swiper-container-flip .swiper-slide-active,
.swiper-container-cube .swiper-slide-active .swiper-slide-active,
.swiper-container-flip .swiper-slide-active .swiper-slide-active {
	pointer-events: auto;
}
.swiper-container-cube .swiper-slide-shadow-top,
.swiper-container-flip .swiper-slide-shadow-top,
.swiper-container-cube .swiper-slide-shadow-bottom,
.swiper-container-flip .swiper-slide-shadow-bottom,
.swiper-container-cube .swiper-slide-shadow-left,
.swiper-container-flip .swiper-slide-shadow-left,
.swiper-container-cube .swiper-slide-shadow-right,
.swiper-container-flip .swiper-slide-shadow-right {
	z-index: 0;
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	-ms-backface-visibility: hidden;
	backface-visibility: hidden;
}
.swiper-container-cube .swiper-slide {
	visibility: hidden;
	-webkit-transform-origin: 0 0;
	-moz-transform-origin: 0 0;
	-ms-transform-origin: 0 0;
	transform-origin: 0 0;
	width: 100%;
	height: 100%;
}
.swiper-container-cube.swiper-container-rtl .swiper-slide {
	-webkit-transform-origin: 100% 0;
	-moz-transform-origin: 100% 0;
	-ms-transform-origin: 100% 0;
	transform-origin: 100% 0;
}
.swiper-container-cube .swiper-slide-active,
.swiper-container-cube .swiper-slide-next,
.swiper-container-cube .swiper-slide-prev,
.swiper-container-cube .swiper-slide-next + .swiper-slide {
	pointer-events: auto;
	visibility: visible;
}
.swiper-container-cube .swiper-cube-shadow {
	position: absolute;
	left: 0;
	bottom: 0px;
	width: 100%;
	height: 100%;
	background: #000;
	opacity: 0.6;
	-webkit-filter: blur(50px);
	filter: blur(50px);
	z-index: 0;
}
.swiper-container-fade.swiper-container-free-mode .swiper-slide {
	-webkit-transition-timing-function: ease-out;
	-moz-transition-timing-function: ease-out;
	-ms-transition-timing-function: ease-out;
	-o-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
}
.swiper-container-fade .swiper-slide {
	pointer-events: none;
	-webkit-transition-property: opacity;
	-moz-transition-property: opacity;
	-o-transition-property: opacity;
	transition-property: opacity;
}
.swiper-container-fade .swiper-slide .swiper-slide {
	pointer-events: none;
}
.swiper-container-fade .swiper-slide-active,
.swiper-container-fade .swiper-slide-active .swiper-slide-active {
	pointer-events: auto;
}
.swiper-scrollbar {
	border-radius: 10px;
	position: relative;
	-ms-touch-action: none;
	background: rgba(0, 0, 0, 0.1);
}
.swiper-container-horizontal > .swiper-scrollbar {
	position: absolute;
	left: 1%;
	bottom: 3px;
	z-index: 50;
	height: 5px;
	width: 98%;
}
.swiper-container-vertical > .swiper-scrollbar {
	position: absolute;
	right: 3px;
	top: 1%;
	z-index: 50;
	width: 5px;
	height: 98%;
}
.swiper-scrollbar-drag {
	height: 100%;
	width: 100%;
	position: relative;
	background: rgba(0, 0, 0, 0.5);
	border-radius: 10px;
	left: 0;
	top: 0;
}
.swiper-scrollbar-cursor-drag {
	cursor: move;
}
.swiper-lazy-preloader {
	width: 42px;
	height: 42px;
	position: absolute;
	left: 50%;
	top: 50%;
	margin-left: -21px;
	margin-top: -21px;
	z-index: 10;
	-webkit-transform-origin: 50%;
	-moz-transform-origin: 50%;
	transform-origin: 50%;
	-webkit-animation: swiper-preloader-spin 1s steps(12, end) infinite;
	-moz-animation: swiper-preloader-spin 1s steps(12, end) infinite;
	animation: swiper-preloader-spin 1s steps(12, end) infinite;
}
.swiper-lazy-preloader:after {
	display: block;
	content: "";
	width: 100%;
	height: 100%;
	background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%236c6c6c'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
	background-position: 50%;
	-webkit-background-size: 100%;
	background-size: 100%;
	background-repeat: no-repeat;
}
.swiper-lazy-preloader-white:after {
	background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%23fff'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}
@-webkit-keyframes swiper-preloader-spin {
	100% {
		-webkit-transform: rotate(360deg);
	}
}
@keyframes swiper-preloader-spin {
	100% {
		transform: rotate(360deg);
	}
}
/*================  Custom styles ===============*/

.swiper-slide {
	background-position: center top;
	&__link {
		display: block;
		height: 100%;
	}
}

.swiper-pagination {

}

.swiper-button-next,
.swiper-button-prev {
}

.swiper-scrollbar {
	.swiper-scrollbar-drag {
	}
}

@media (min-width: 1200px) and (max-width: 1400px) {
	.swiper-slide .caption {
  		padding-left: 300px;
  		padding-right: 300px;
	}
}

@media (max-width: 1199px) {
	.swiper-slide .caption {
		top: 58%;
  		@include transform(translateY(-34%));
  		h6{
  			margin-bottom: 15px;
  		}
	}
	.swiper-button:hover{
    	.swiper-button__arrow{
    	    width: 56px;
    		height: 56px;
    		i{
    			line-height: 54px;
    		}
    	}
  	}	
}

@media (max-width: 991px) {
	.swiper-slide .caption {
		top: 46%;
  		@include transform(translateY(-34%));
	}
}

@media (max-width: 767px) {
	.swiper-slide .caption {
		top: 30%;
		padding: 0 30px;
  		@include transform(translateY(-25%));
  		h2{
  			font-size: 30px;
  			line-height: 34px;
  			margin-bottom: 10px;
  			letter-spacing: 1px;
  		}
  		h6{
  			font-size: 24px;
  			line-height: 30px;
  			margin-bottom: 15px;
  		}
	}
	.swiper-button{
		.preview__img{
    		display: none;
    	}
  	}
  	 .swiper-button.swiper-button-next,
  	 .swiper-button.swiper-button-prev{
  	 	&:hover{
  	 		i{
  	 			color: $tetriary;
  	 		}
  	 		.swiper-button__arrow{
  	 			border-color: $tetriary;
  	 		}
  	 		
  	 	}
  	 }
}

@media (max-width: 480px) {
	.swiper-container{
		height: 430px!important;
	}
	.swiper-button {
		top: 61%;
  		&.swiper-button-next {
  			right: 20px;
  		}
  		&.swiper-button-prev {
  			left: 20px;
  		}
  	}
	.swiper-slide .caption {
		padding: 0 20px;
		.inner_block h3 {
			font-size: 36px;
		}
	}
	.preview__img{
		display: none;
	}
}