@import "include/_variables.scss";
@import "include/_mixins.scss";
@import "components/_bootstrap_reset.scss";

@font-face {
	font-family: 'gilroy';
	src: url('/fonts/gilroy/subset-Gilroy-ExtraBold.woff2') format('woff2'),
	url('/fonts/gilroy/subset-Gilroy-ExtraBold.woff') format('woff'),
	url('/fonts/gilroy/subset-Gilroy-ExtraBold.ttf') format('truetype'),
	url('/fonts/gilroy/subset-Gilroy-ExtraBold.svg#gilroyextrabold') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto';
	src: url('/fonts/roboto/subset-Roboto-MediumItalic.eot');
	src: url('/fonts/roboto/subset-Roboto-MediumItalic.eot?#iefix') format('embedded-opentype'),
	url('/fonts/roboto/subset-Roboto-MediumItalic.woff2') format('woff2'),
	url('/fonts/roboto/subset-Roboto-MediumItalic.ttf') format('truetype');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'Roboto';
	src: url('/fonts/roboto/subset-Roboto-BoldItalic.eot');
	src: url('/fonts/roboto/subset-Roboto-BoldItalic.eot?#iefix') format('embedded-opentype'),
	url('/fonts/roboto/subset-Roboto-BoldItalic.woff2') format('woff2'),
	url('/fonts/roboto/subset-Roboto-BoldItalic.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'Roboto';
	src: url('/fonts/roboto/subset-Roboto-Italic.eot');
	src: url('/fonts/roboto/subset-Roboto-Italic.eot?#iefix') format('embedded-opentype'),
	url('/fonts/roboto/subset-Roboto-Italic.woff2') format('woff2'),
	url('/fonts/roboto/subset-Roboto-Italic.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Roboto';
	src: url('/fonts/roboto/subset-Roboto-Black.eot');
	src: url('/fonts/roboto/subset-Roboto-Black.eot?#iefix') format('embedded-opentype'),
	url('/fonts/roboto/subset-Roboto-Black.woff2') format('woff2'),
	url('/fonts/roboto/subset-Roboto-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto';
	src: url('/fonts/roboto/subset-Roboto-Light.eot');
	src: url('/fonts/roboto/subset-Roboto-Light.eot?#iefix') format('embedded-opentype'),
	url('/fonts/roboto/subset-Roboto-Light.woff2') format('woff2'),
	url('/fonts/roboto/subset-Roboto-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto';
	src: url('/fonts/roboto/subset-Roboto-Regular.eot');
	src: url('/fonts/roboto/subset-Roboto-Regular.eot?#iefix') format('embedded-opentype'),
	url('/fonts/roboto/subset-Roboto-Regular.woff2') format('woff2'),
	url('/fonts/roboto/subset-Roboto-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto';
	src: url('/fonts/roboto/subset-Roboto-Medium.eot');
	src: url('/fonts/roboto/subset-Roboto-Medium.eot?#iefix') format('embedded-opentype'),
	url('/fonts/roboto/subset-Roboto-Medium.woff2') format('woff2'),
	url('/fonts/roboto/subset-Roboto-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto';
	src: url('/fonts/roboto/subset-Roboto-ThinItalic.eot');
	src: url('/fonts/roboto/subset-Roboto-ThinItalic.eot?#iefix') format('embedded-opentype'),
	url('/fonts/roboto/subset-Roboto-ThinItalic.woff2') format('woff2'),
	url('/fonts/roboto/subset-Roboto-ThinItalic.ttf') format('truetype');
	font-weight: 100;
	font-style: italic;
}

@font-face {
	font-family: 'Roboto';
	src: url('/fonts/roboto/subset-Roboto-Thin.eot');
	src: url('/fonts/roboto/subset-Roboto-Thin.eot?#iefix') format('embedded-opentype'),
	url('/fonts/roboto/subset-Roboto-Thin.woff2') format('woff2'),
	url('/fonts/roboto/subset-Roboto-Thin.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto';
	src: url('/fonts/roboto/subset-Roboto-BlackItalic.eot');
	src: url('/fonts/roboto/subset-Roboto-BlackItalic.eot?#iefix') format('embedded-opentype'),
	url('/fonts/roboto/subset-Roboto-BlackItalic.woff2') format('woff2'),
	url('/fonts/roboto/subset-Roboto-BlackItalic.ttf') format('truetype');
	font-weight: 900;
	font-style: italic;
}

@font-face {
	font-family: 'Roboto';
	src: url('/fonts/roboto/subset-Roboto-LightItalic.eot');
	src: url('/fonts/roboto/subset-Roboto-LightItalic.eot?#iefix') format('embedded-opentype'),
	url('/fonts/roboto/subset-Roboto-LightItalic.woff2') format('woff2'),
	url('/fonts/roboto/subset-Roboto-LightItalic.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Roboto';
	src: url('/fonts/roboto/subset-Roboto-Bold.eot');
	src: url('/fonts/roboto/subset-Roboto-Bold.eot?#iefix') format('embedded-opentype'),
	url('/fonts/roboto/subset-Roboto-Bold.woff2') format('woff2'),
	url('/fonts/roboto/subset-Roboto-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

/*========================================================
                      Main Styles
=========================================================*/
body {
	font: $baseFont;
	color: $baseColor;
}

h1, h2, h3, h4, h5, h6 {
	color: $textPrimary;
	margin: 0 0 20px;
	line-height: normal;
	font-family: 'gilroy', sans-serif;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility !important;
}

h3, h4, h5, h6 {
	font-weight: 500;
}

/* Override the bootstrap defaults */
h1 {
	text-transform: uppercase;
	font-size: 34px;
	line-height: 55px;
	margin-bottom: 0;
	text-align: center;
	-ms-text-overflow: ellipsis;
	text-overflow: ellipsis;
	overflow: hidden;
	i {
		margin-right: 10px;
		color: $primary;
		font-size: 45px;
		&:before {
			vertical-align: middle;
		}
	}
	&:after {
		content: '';
		height: 1px;
		display: block;
		background: $borderColor;
		margin: 15px 0px 15px;
	}
	&.no-line {
		margin-bottom: 30px;
		&:after {
			display: none;
		}
	}
}

h2 {
	font-size: 26px;
	text-align: center;
	@media (max-width: 991px) {
		font-size: 24px;
	}
	@media (max-width: 767px) {
		margin-top: 10px;
	}
	@media (max-width: 379px) {
		font-size: 20px;
	}
}

h3 {
	font-size: 17px;
	text-transform: uppercase;
	text-align: center;
}

h4 {
	font-size: 20px;
}

h5 {
	font-size: 16px;
}

h6 {
	font-size: 10.2px;
}

a {
	display: inline-block;
	font: inherit;
	color: inherit;
	text-decoration: none;
	max-width: 100%;
	&:active {
		background-color: transparent;
	}
	&:visited, &:focus {
		color: inherit;
		outline: none;
		text-decoration: none;
	}
	&:hover {
		text-decoration: none;
		color: $primary;
	}
	@include transition(.3s all ease);
}

p {
	a, a:visited, a:focus {
		color: $primary;
		&:hover {
			text-decoration: underline;
		}
	}
}

img {
	max-width: 100%;
	height: auto;
}

legend {
	font-size: 18px;
	padding: 7px 0px
}

label {
	font-size: 12px;
	font-weight: normal;
}

strong, b {
	color: $textPrimary;
}

.input-group .input-group-addon {
	height: 30px;
}

.nav-tabs {
	margin-bottom: 15px;
}

div.required .control-label:after {
	content: '*';
	color: #ee372a;
	font-weight: bold;
	margin-left: 5px;
}

.fa {
	line-height: inherit;
	font-family: inherit;
	&:before {
		font-family: $fa;
	}
}

#page > .ie-warning {
	position: relative;
	text-align: center;
	display: none;
	clear: both;
	.lt-ie11 & {
		@media (min-width: 768px) {
			display: block;
		}
	}
}

.modal {
	z-index: 310005 !important;
}

/*========================================================
                      Header styles
=========================================================*/
@import "sections/_header.scss";
/*========================================================
                      Content styles
=========================================================*/
@import "sections/_content.scss";
/* ================   Imports   ===============*/
/* Components*/
@import "components/widgets/_lazy-img.scss";
@import "components/_search-form.scss";
@import "components/_cart.scss";
@import "components/_product.scss";
@import "components/_box.scss";
@import "components/_buttons.scss";
@import "components/_forms.scss";
@import "components/_page-preloader.scss";

/* Menu*/
@import "components/menu/_sf-menu.scss";
@import "components/menu/_stuck-menu.scss";
@import "components/menu/_navbar.scss";
/* Modules*/
@import "modules/_banners.scss";
@import "modules/_html_boxes.scss";
@import "modules/_module-tabs.scss";
@import "modules/_newsletter.scss";
@import "modules/_newsletter-popup.scss";
@import "modules/_owl-carousel.scss";
@import "modules/_ajax_quick_view.scss";
@import "modules/_swiper.scss";
@import "modules/_rd-parallax.scss";

/* ===================   Breadcrumb   =================== */
.breadcrumb {
	background: transparent;
	font-size: 14px;
	line-height: 23px;
	a {
		display: inline-block;
		color: $baseColor;
		&:hover {
			color: $primary;
			i {
				color: $primary;
			}
		}
	}
	> li {
		position: relative;
		> a,
		> a > i,
		> span,
		+ li:before {
			vertical-align: middle;
			color: $baseColor;
		}
		+ li {
			&:before {
				content: '\e0c2';
				padding: 0;
				font-family: $material;
				font-size: 20px;
				line-height: 20px;
			}
		}
	}
	i {
		&.fa {
			font-size: 24px;
		}
		@include transition(color .3s ease);
		&:before {
			content: '\e0ba';
			font-family: $material;
		}
	}
	@media (max-width: 991px) {
		font-size: 14px;
	}
	@media (max-width: 767px) {
		font-size: 12px;
	}
}

.success {
	display: none;
	width: 600px;
	margin-left: -300px;
	background: white;
	border: 1px solid #e4e3e3;
	padding: 50px;
	left: 50%;
	top: 40%;
	font-size: 15px;
	color: #333333 !important;
	-webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
	-moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
	z-index: 25000;
	position: fixed;

	a {
		text-decoration: none !important;
		font-size: 15px;
		&:hover {
			color: #ec1b24;
			text-decoration: underline;
		}
	}
	.close {
		float: right;
		position: absolute;
		top: 20px;
		right: 20px;
		cursor: pointer;
		opacity: 0.7;
	}
	.success_button {
		background: #6e9ecf;
		border: none;
		display: inline-block;
		cursor: pointer;
		color: white !important;
		font-size: 15px;
		font-weight: bold;
		padding: 6px 24px;
		text-decoration: none;
	}
	.success_button:hover {
		text-decoration: none !important;
		color: #fff !important;
		background: #0264af;
	}
	.success_button:active {
		position: relative;
		top: 1px;
	}
}

/* ===================   Product Page   =================== */

.product-share {
	height: 32px;
	margin-top: 1em;
}

.review-form-title {
	text-align: center;
	h3 {
		cursor: pointer;
		position: relative;
		border-bottom: 1px solid $borderColor;
		padding: 0 10px 5px;
		display: inline-block;
		@include transition(.3s all ease);
		&:before {
			position: absolute;
			bottom: -20px;
			left: 50%;
			width: 0;
			height: 0;
			border: 10px solid transparent;
			border-top-color: $borderColor;
			content: '';
			margin-left: -10px;
		}
		&:hover {
			color: $primary;
		}
	}
}

.product-rating-status {
	.fa-stack + .review-link,
	.review-link + .review-link {
		border-left: 1px solid $borderColor;
	}
	.review-link {
		position: relative;
		padding-left: 7px;
		margin-left: 7px;
		vertical-align: middle;
	}
}

.product-options {
	padding-bottom: 20px;
	border-bottom: 1px solid $borderColor;
	.control-label {
		text-align: left;
		margin: 0 0 10px;
		color: $textPrimary;
		text-transform: uppercase;
		font-size: $baseFontSize;
	}
	.radio {
		padding-top: 0;
		input[type="radio"] + label {
			padding: 5px 5px 5px 45px;
			display: block;
			background: $stockBg;
			text-transform: capitalize;
			text-align: left;
			&:before {
				top: 5+($baseLineHeight - $radio-size)/2;
				left: 13px;
			}
			&:after {
				top: 5+(($radio-size - $radio-inner-size)/2) + ($baseLineHeight - $radio-size)/2;
				left: 13+(($radio-size - $radio-inner-size)/2);
			}
		}
		+ .radio {
			margin-top: 15px;
		}
	}

	.checkbox {
		label {
			padding: 5px 5px 5px 45px;
			display: block;
			background: $stockBg;
			text-transform: capitalize;
			text-align: left;
			&:before, &:after {
				left: 13px;
				top: 5px + ($baseLineHeight - $checkbox-size)/2;
			}
			&:before {
				background: none;
				border: 1px solid $borderColor;
			}
		}
	}

}

.table-hover > tbody > tr:hover {
	background: #fafafa;
}

.fancybox-skin {
	background: #fff !important;
	.fancybox-nav {
		.fancybox-prev {
			@include transform(translateX(-50%));
		}
		.fancybox-next {
			@include transform(translateX(50%));
		}
	}
}

.cat-wrapper {
	margin-bottom: 20px;
	h3 {
		margin-top: 15px;
	}
	.thumb {
		background: #fff;
	}
}

body[class^='product-category-'], body[class^='product-allproduct'], body[class^='product-special'], body[class^='product-search'] {
	#page {
		background: #f7fbff center bottom fixed;
		background-size: cover;
		background-image: url(/image/bg/bg5.jpg);
		/*	@media (max-width: 767px)  {			
				background: center bottom no-repeat;
				background-attachment: scroll;
				background-image: url(/image/bg/bg5mob.jpg);
				background-size: cover;
			}*/
	}
}

.cat-wrapper, .product-wrapper, header + .container, header + .main-container {
	padding-top: 176px;
	@media (max-width: 991px) {
		padding-top: 142px;
	}
}

@media (max-width: 767px) {
	.cat-wrapper, .product-wrapper, header + .container, header + .main-container {
		padding-top: 88px;
	}
	header {
		height: 88px;
		border-bottom: 1px #eee solid;
	}
}

#ui-to-top {
	font-size: 38px;
	padding: 3px;
	@include box-shadow(0px 0px 3px #fff);
}

#product-attrs {
	background: #fff;
	padding: 0 30px;
	.scroll-fix-attr {
		position: fixed;
		top: 167px;
		left: auto;
		right: auto;
		z-index: 7000;
		@media (max-width: 1199px) {
			top: 157px;
		}
		@media (max-width: 991px) {
			top: 137px;
		}
		@media (max-width: 767px) {
			top: 144px;
		}
	}
	clear: both;
	.prod-anchor {
		position: absolute;
		top: -240px;
		@media (max-width: 767px) {
			top: -215px;
		}
		visibility: hidden;
		display: block;
		height: 0;
		width: 0;
	}
	.product-section {
		position: relative;
		h3 {
			padding: 15px 5px;
			font-size: 18px;
			text-align: left;
			font-family: "Roboto", sans-serif;
			background: #f9f9f9;
			font-weight: bold;
			line-height: 1.625;
		}
		&.product-gifts {
			ul {
				padding: 0;
				list-style: none;
				li {
					&:after {
						content: '\0418\041B\0418';
						display: block;
						text-align: center;
						font-size: 20px;
						color: $headerBg;
					}
					&:last-child:after {
						content: none;
					}
				}
				a {
					display: flex;
					align-items: center;
				}
				div {
					display: inline-block;
					img {
						width: 160px;
					}
					&.gift_name {
						font-size: 24px;
						color: $headerBg;
						margin-left: 15px;
						&:hover {
							color: $primary;
						}
					}
				}
			}
			p {
				margin: 20px 0 0 0;
			}
			@media(max-width: 520px) {
				ul a {
					flex-wrap: wrap;
				}
				div {
					width: 100%;
				}
				.gift_image {
					text-align: center;
					margin-bottom: 20px;
				}
			}
		}
	}
	.product-review {
		.control-label {
			font-family: Roboto, sans-serif;
			font-size: 16px;
			margin: 5px;
		}
		.recaptcha_container .control-label {
			display: none;
		}
		input[type="radio"] {
			display: initial;
		}
		#review {
			color: $textPrimary;
			.review-item {
				margin: 20px 0;
				border: 1px solid $grey;
				padding: 15px;
				.review-header {
					display: flex;
					justify-content: space-between;
					margin-bottom: 15px;
					.review-left-header {
						display: flex;
						flex-wrap: wrap;
						.review-author {
							margin-right: 15px;
							min-width: 85px;
							font-weight: 500;
						}
					}
					.review-right-header {
						.rating {
							color: $yellow;
							min-width: 125px;
						}
					}
				}
				.review-main {
					& > div {
						display: flex;
						@media (max-width: 767px) {
							flex-wrap: wrap;
						}
					}
					.review-title {
						font-weight: 500;
						min-width: 100px;
					}
				}
			}
		}
	}
	.nav-tabs {
		border-top: 2px solid $primary;
		@include clearfix;
		width: auto;
		float: none;
		background: #fff;
		text-align: center;
		> li {
			float: none;
			margin-bottom: 0;
			border: none;
			display: inline-block;
			> a {
				@include border-radius(0);
				text-transform: uppercase;
				font-weight: 700;
				font-size: 16px;
				border: none;
				margin-right: 0;
				color: $textPrimary;
				position: relative;
				background: #fff;
				z-index: 0;
				&:hover {
					color: $secondary;
					background: #fff;
				}
				&:focus {
					border-top: none;
				}
			}
			&.active > a {
				cursor: pointer;
				color: $primary;
				background-color: #eee;
				&:hover {
					background: #fff;
					border-top: none;
				}
			}
		}
	}
	.tab-content {
		@include box-sizing(border-box);
	}
	@media (max-width: 991px) {
		.nav-tabs > li > a {
			padding: 20px 16px;
			font-size: 14px;
		}
	}
	@media (max-width: 767px) {
		#product-attrs .prod-anchor {
			top: -95x;
		}
		.nav-tabs {
			background: #fff;
			& > li:last-child {
				border-right: none;
			}
			& > li > a {
				font-size: 12px;
				padding: 8px;
				&:before {
					display: none;
				}
			}
			& > li.active > a {
				background: #eee;
			}
		}
	}
	@media (max-width: 387px) {
		.nav-tabs > li > a {
			font-size: 10px;
			padding: 7px 4px;
		}
	}

	.product-spec {
		.table {
			border: none;
			tbody {
				border: 1px #ddd solid;
			}
			thead td {
				padding: 0;
				background: #fff;
			}
			th {
				text-align: left;
				text-transform: uppercase;
				border: none;
				padding: 15px 5px;
				font-size: 18px;
				padding: 0;
				background: #fff;
				h3 {
					margin-top: 20px;
				}
			}
			td {
				line-height: $baseLineHeight;
				padding: (50 - $baseLineHeight)/2 15px;
				border-bottom: none;
				border-top: none;
			}
			tr + tr td {
				border-top: 1px solid $borderColor;
			}
			td:first-child {
				width: 30%;
				border-left: none;
				text-align: left;
				color: $textPrimary;
			}
			td {
				width: 70%;
				border-right: none;
			}
			@media (max-width: 479px) {
				td:first-child,
				td {
					width: 50%;
				}
			}
		}
	}

	@media (max-width: 767px) {
		padding: 0px;
		& > div {
			padding: 0 10px;
		}
		.product_tabs {
			.nav-tabs, .tab-content {
				width: 100%;
				float: none;
			}
			.tab-content {
				border-left: none;
				padding: 20px 0;
			}
		}
	}
}

.product-title {
	margin: 0 0 20px;
	color: $textPrimary;
	text-align: left;
}

.reward-block span {
	display: block;
}

#product {
	margin-top: 15px;
	.input-group[class*="col-"] {
		padding-left: 15px;
		padding-right: 15px;
	}
	label {
		color: $textPrimary;
		text-transform: uppercase;
	}
	.quantity {
		margin-top: 20px;
		label {
			margin-right: 5px;
		}
	}
	.compare-btn {
		margin-left: 10px;
	}
	.product-btn-add {
		float: left;
		@media (max-width: 767px) {
			margin-bottom: 10px !important;
		}
	}
}

.polaroid {
	@include box-shadow(none);
	background: $stockBg;
	padding: 0;
}

.magnificent-viewport {
	background: $stockBg;
}

.general_info {
	margin-top: -7px;
	.price-section {
		margin: 15px 0 0;
		padding-bottom: 10px;
		border-bottom: 1px solid $borderColor;
	}
	.product-section {
		padding: 10px 0;
		strong {
			color: $textPrimary;
		}
	}
	.prod_rating {
		border-bottom: 1px solid $borderColor;
		#scr_newrev, #scr_revs {
			color: $primary;
			font-weight: 700;
			&:hover {
				cursor: pointer;
			}
		}
		.fa {
			color: $yellow;
		}
	}
	a {
		color: $primary;
		&:hover {
			color: $textPrimary;
		}
	}
}

.product-video {
	.r_frame {
		position: relative;
	}
	iframe {
		position: relative;
		left: 0;
		top: 0;
		width: 100% !important;
		/*	height: 100% !important;*/
	}
}

.storage_widget {
	b {
		color: inherit;
	}
}

/* ===================   Product Gallery   =================== */
@media (max-width: 1199px) {
	.zoomContainer {
		display: none !important;
	}
}

#productFullGallery {
	img {
		padding: 0 75px;
	}
}

.lightbox {
	bottom: 50px;
}

.product-gallery {
	@include clearfix;
	.image-thumb {
		overflow: hidden;
		.bx-wrapper {
			margin: 0 !important;
			.bx-viewport {
				@include box-sizing(content-box);
				z-index: 10;
				&:after {
					@include border-radius (50%);
				}
			}
			.bx-controls .bx-pager {
				text-align: center;
				top: 19px;
				position: relative;
				.bx-pager-item {
					display: inline-block;
					height: 15px;
					width: 15px;
					border-radius: 50%;
					background: #fff;
					margin: 0 3px;
					box-shadow: inset -1px -1px 1px 1px #bbb;
					overflow: hidden;
					a {
						font-size: 0 !important;
						display: inline-block;
						width: 100%;
						height: 100%;
						line-height: 34px;
						&.active {
							background: #ccc;
						}
					}
				}
			}
		}
		.bx-prev, .bx-next {
			position: absolute;
			font-size: 40px;
			@media (max-width: 767px) {
				font-size: 30px;
			}
			line-height: 100px;
			background: transparent;
			text-align: center;
			margin: 0;
			color: $primary;
			top: 50%;
			transform: translateY(-50%);
			z-index: 17;
			&.disabled {
				display: none;
				visibility: hidden;
			}
			&:hover {
				color: #6e9ecf;
			}
		}
		.bx-prev {
			left: 15px;
		}
		.bx-next {
			right: 15px;
		}
		@media (max-width: 1199px) {
			.bx-prev, .bx-next {
				height: 56px;
				line-height: 56px;
				display: inline-block;
			}
			.bx-next {
				left: auto;
				right: 15px;
				bottom: calc(50% - 15px);
			}
			.bx-prev {
				left: 15px;
				right: auto;
			}
		}
		li a {
			border: none;
			background: transparent;
			display: block;
			@media (min-width: 1200px) {
				&:hover, &.zoomGalleryActive {
					/*	background: transparent;
						@include box-shadow($baseBoxShadow);*/
				}
			}
		}
	}
}

@media (max-width: 767px) {
	#product {
		margin-top: 0px;
	}
	#content {
		padding-bottom: 0px;
	}
	.product_page-left {
		+ .product_page-right {
			margin-top: 25px;
		}
	}
}

.image-additional {
	margin: 0 -10px 0 0;
	padding: 0 10px 0 0;
	list-style-type: none;
}

.product-reviews {
	input[type="radio"] {
		display: inline;
		outline: none;
		&:focus {
			outline: none;
		}
	}
	.review-item {
		margin-bottom: 2em;
	}
}
.product-review {
	i.star {
		color: $yellow;
	}
}

.fancybox-overlay {
	z-index: 18218 !important;
}

/* ===================   Sub Category on Category Page   =================== */
.box-subcat {
	padding: 0;
	margin: 0;
	list-style-type: none;
	text-align: center;
	li {
		width: calc(16%);
		display: inline-block;
		float: none;
		text-align: center;
		margin-bottom: 15px;
		vertical-align: bottom;
		.thumb {
			padding: 10px 0;
			border: 1px solid $borderColor;
			@include border-radius(11px);
			.subcatname {
				vertical-align: bottom;
			}
		}
		.name {
			a {
				text-transform: none;
			}
		}
		.image {
			overflow: hidden;
			margin-bottom: 15px;
			a {
				display: block;
				padding: 10px;
				/*				&:hover img {
									opacity: 0.7;
								}*/
			}
			img {
				@include transition(.3s all ease);
			}
			&:hover {
				img {

				}
			}
		}
		&.first-in-line {
			clear: left;
		}
	}
}

.product-allproduct .box-subcat li {
	width: 20%;
	@media (max-width: 767px) {
		width: 49%;
	}
}

.pagination > li + li:nth-child(5n+6) {
	clear: none;
	a {
		border-left: none;
	}
}

@media (max-width: 767px) {
	.box-subcat {
		padding: 0 25px;
		li {
			width: 30%;
			padding: 0 7px;
		}
	}
}

@media (max-width: 550px) {
	.box-subcat li {
		width: 49%;
		float: none;
		clear: both;
	}
}

/* ===================   Product Filter  =================== */
.button-view {
	button + button {
		border-left: 1px solid #eee;
	}
	a, button {
		width: 50px;
		height: 50px;
		line-height: 50px;
		text-align: center;
		display: inline-block;
		font-size: 24px;
	}
}

.pagination > li > a, .pagination > li > span {
	background: #fff;
}

#list-view,
#grid-view {
	&.active,
	&:hover {
		color: $primary;
	}
	&.active:hover {
		color: $secondary;
	}
}

#input-sort {
	font-size: 14px;
}

.product-filter {
	display: initial;
	background: #fff;
	margin: 15px 0 30px;
	border: none;
	@include border-radius(5px);
	&_elem {
		float: right;
		.sbHolder, select {
			height: 35px;
			@include border-radius(0);
			padding: 8.5px 7px;
			border-top: none;
			border-bottom: none;
			.sbSelector {
				padding: 4.5px 10px;
				font-size: 14px;
			}
			.sbToggle {
				line-height: 33px;
			}
		}
		&.show {
			.sbHolder {
				width: auto;
				border-right: none;
			}
		}
	}
	.sort {
		width: 31%;
		@media(max-width: 1199px) {
			width: 60%;
		}
		text-align: right;
		margin: 20px 0;
	}
	label, .sbHolder {
		display: inline-block;
		vertical-align: middle;
		border: 1px #d8d8d8 solid;
	}
	label {
		margin: 0 5px 0 0;
		color: #777;
		border: none;
		text-transform: uppercase;
	}
	.compare-total {
		border-right: 1px solid #ccc;
		color: #ccc;
		span {
			display: none;
		}
		&:hover {
			color: $primary;
		}
	}
}

.results {
	margin-top: 13px;
}

.nav-cat {
	margin-bottom: 13px;
	.nam-page {
		margin: 5px 0 5px 10px;
	}
}

/* ========   Cart page   ============= */
.checkout-cart, .account-wishlist, .product-compare {
	#content {
		h1 {
			margin: 30px 0 50px;
		}
		.table {
			a {
				color: $primary;
				font-weight: 700;
				&:hover {
					color: $textPrimary;
				}
			}
			p .link {
				margin-top: 5px;
			}
		}

		.table.table-bordered {
			tr td:nth-child(2) {
				min-width: 150px;
				background: #fff;
			}
		}
		.table-custom {
			.price {
				font-weight: 700;
			}
			td:first-child {
				text-transform: uppercase;
				color: $textPrimary;
			}
			tr:last-child {
				td {
					font-weight: 700;
					color: $textPrimary;
				}
				.price {
					color: $primary;
					font-size: 28px;
				}
			}
		}
	}
}

.checkout-cart #content .table td:nth-child(3) {
	min-width: 150px;
}

.checkout-checkout #content {
	#collapse-checkout-option {
		.col-sm-6 + .col-sm-6 {
			> h2 {
				margin-top: 25px;
			}
		}
	}
	.table-responsive .table {
		tfoot {
			td:last-child {
				color: $textPrimary;
			}
			tr:last-child {
				td {
					font-size: 18px;
					&:last-child {
						color: $primary;
						font-weight: 700;
					}
				}
			}
		}
	}
}

#accordion {
	.btn.btn-primary {
		@include border-radius(25px);
		@include transition(all 0.4s ease);
	}
}

.account-wishlist .table.table-bordered tr td:nth-child(6) {
	min-width: 150px;
}

.cart-wrapper {
	padding: 30px;
	border: 1px solid $borderColor;
	border-top-width: 2px;
}

.cart-remove-btn, .cart-remove-btn:visited {
	display: inline-block;
	font-size: 22px;
	color: $primary;
	&:focus {
		text-decoration: none;
	}
	span {
		font-size: 14px;
	}
	&:hover {
		text-decoration: none;
		color: $textPrimary;
	}
}

.link {
	color: $primary;
	font-size: 14px;
	font-weight: 700;
	&:focus, &:visited {
		color: $primary;
	}
	&:hover {
		color: $textPrimary;
	}
}

@media (max-width: 767px) {
	.cart-wrapper {
		padding: 30px 0;
		border-left: none;
		border-right: none;
		border-bottom: none;
	}
}

@media (max-width: 500px) {
	.checkout-cart #content .table.table-bordered {
		td:first-child, td:nth-child(4) {
			display: none;
		}
	}
	.account-wishlist #content .table {
		td:first-child, td:nth-child(3) {
			display: none;
		}
	}
}

/* ========   BLOCK COMPARE   ============= */
.product-compare #content {
	@media (min-width: 768px) {
		table {
			table-layout: fixed;
		}
	}
	.table td:first-child {
		min-width: 15%;
		color: $textPrimary;
	}
	.table td {
		background: #fff;
	}
	.product-thumb {
		padding: 0 20px;
		@media (max-width: 767px) {
			padding: 0;
		}
	}
}

.rating .review {
	display: inline-block;
	margin-left: 5px;
	padding-left: 5px;
	position: relative;
	border-left: 1px solid $borderColor;
}

.product-thumb .cart-remove-btn {
	position: absolute;
	font-size: 22px;
	right: 0;
	top: 0;
}

@media (max-width: 1199px) {
	.product-compare .table-responsive {
		border: none;
		.table-bordered {
			border: 2px solid $borderColor;
		}
		overflow-x: scroll;
	}
}

@media (max-width: 767px) {
	.product-compare {
		.table-responsive {
			margin-bottom: 0;
			.product-thumb .name > a {
				font-size: 14px;
			}
			font-size: 12px;
			td {
				max-width: 150px !important;
				white-space: normal !important;
				min-width: 150px;
			}
		}
	}
	.rating .review {
		display: block;
		margin-left: 0;
		padding-left: 0;
		border: none;
	}

}

/* ===================   About Page   =================== */
.extra-wrap {
	overflow: hidden;
}

.information-information #content {
	h4, h5 {
		margin: 0 0 20px;
		line-height: normal;
		text-transform: uppercase;
		color: $primary;
		a:hover {
			color: $textPrimary;
		}
	}
	h3 {
		text-transform: uppercase;
		font-size: 34px;
		line-height: 40px;
		margin-bottom: 0;
		&:after {
			content: '';
			height: 1px;
			display: block;
			background: $borderColor;
			margin: 20px 0 40px;
		}
		&.clear {
			margin-bottom: 30px;
			&:after {
				display: none;
			}
		}
	}

	h5 {
		color: $primary;
	}
	h1, h2, h3, h4, h5 {
		font-weight: 500;
	}

	* + h3 {
		margin-top: 60px;
	}
	p + h4 {
		margin-top: 40px;
	}

	@media (max-width: 767px) {
		[class*="col-sm"] + [class*="col-sm"] {
			margin-top: 30px;
		}
	}
}

/* ===================   Count box   =================== */
.count-box {
	margin-top: 40px;
	strong {
		display: block;
		color: $primary;
		font-weight: 500;
		font-size: 56px;
		line-height: 60px;
		margin-bottom: 15px;
	}
}

/* ===================   Icon box   =================== */
.icon-box {
	i {
		display: inline-block;
		width: 120px;
		height: 120px;
		line-height: 120px;
		text-align: center;
		border: 2px solid $primary;
		font-size: 66px;
		color: $primary;
		margin-bottom: 25px;
		@include border-radius(50%);
		@include box-sizing(border-box);
	}
}

/* ===================   Testimonials   =================== */
.testimonials blockquote {
	border: 0;
	padding: 0 20px;
	&:before {
		content: '\e09d';
		display: inline-block;
		color: $primary;
		font-size: 84px;
		line-height: 100px;
		font-family: $material;
		margin-bottom: 20px;
	}
}

/* ===================   Thumbnail   =================== */
.thumbnail {
	background: none;
	border: none;
	padding: 10px;
	margin: 0 auto 25px;
	border-radius: 0;
	overflow: hidden;
	border: 1px #ddd solid;
	ul li {
		text-align: left;
	}
	img {
		@include transition(.3s transform ease);
	}
	a {
		display: block;
		max-width: 100%;
		&:hover img {
			@include transform(scale(1.2));
		}
		@media (max-width: 767px) {
			display: inline-block;
		}
	}
	@media (max-width: 768px) {
		text-align: center;
		.caption {
			text-align: left;
		}
	}
}

p.thumbnail {
	@media (max-width: 768px) {
		text-align: left;
	}
}

.owl-carousel .owl-buttons div {
	opacity: 1;
	color: #0264af;
}

.img-thumbnail {
	border: 0;
	background-color: #fff;
	padding: 0;
	border-radius: 0;
	.table-bordered & {
		background-color: transparent;
	}
}

.human_info .thumbnail {
	float: left;
	margin-right: 20px;
}

.clear {
	clear: both;
	margin: 0;
	padding: 0;
	font-size: 0;
	border: 0;
	line-height: 0;
}

@media (max-width: 500px) {
	img.alignleft, .human_info .thumbnail {
		float: none;
		margin: 0 auto 20px;
	}
}

/* ===================   Delivery Page   =================== */
.delivery_page {
	text-align: center;
	h4 {
		color: $primary;
	}
	.table {
		border-top: 2px solid $primary;
		text-align: left;
		tbody {
			td:first-child {
				color: $primary;
			}
			td:last-child {
				color: $textPrimary;
				font-weight: 700;
			}
			td {
				min-width: 150px;
			}
		}

	}
}

/* ===================   Terms and Conditions Page   =================== */
.terms_conditions_page {
	h4 {
		position: relative;
		padding-left: 60px;
		&:before {
			content: '\e174';
			position: absolute;
			left: 0;
			bottom: -7px;
			color: $primary;
			font-size: 40px;
			line-height: 40px;
			font-family: $material;
		}
	}
	p + h4 {
		margin-top: 40px;
	}
}

/* ===================   Privacy Policy  =================== */
.privacy_page {
	ol {
		list-style-type: none;
		counter-reset: list;
		padding-left: 0;
		li {
			counter-increment: list;
			h4 {
				&:before {
					content: counter(list, decimal);
					display: inline-block;
					margin-right: 15px;
					width: 40px;
					height: 40px;
					line-height: 40px;
					text-align: center;
					background: $primary;
					color: #fff;
					font-size: 16px;
					@include border-radius(50%);
					@include box-shadow(inset -1px -1px 0 rgba(#000, .2));
				}
			}
			+ li {
				margin-top: 40px;
			}
		}
	}
}

/* ===================   Site Map Page  =================== */
.site-map-page {
	h5 {
		margin: 0 0 20px;
		line-height: normal;
		text-transform: uppercase;
		color: $primary;
	}
	ul + h5 {
		margin-top: 40px;
	}
	#content ul {
		list-style-type: none;
		padding-left: 0;
		li {
			position: relative;
			line-height: $baseLineHeight;
			+ li {
				margin-top: 40 - $baseLineHeight;
			}
			a:hover {
				color: $primary;
			}
		}
		&.category-list {
			position: relative;
			&:before {
				content: '';
				position: absolute;
				left: 0;
				top: $baseLineHeight/2;
				bottom: $baseLineHeight/2;
				width: 1px;
				background: $primary;
			}
			.ul-with-ul {
				&:before, &:after {
					@include translate(0);
				}
				&:before {
					top: $baseLineHeight/2;
				}
				&:after {
					top: ($baseLineHeight - 12)/2;
				}
			}

			ul {
				position: relative;
				margin-top: 40 - $baseLineHeight;
				&:before {
					content: '';
					position: absolute;
					left: 0;
					top: $baseLineHeight/2;
					bottom: $baseLineHeight/2;
					width: 1px;
					background: $borderColor;
				}
			}

			li {
				position: relative;
				padding-left: 60px;
				&:before {
					content: '';
					position: absolute;
					top: 50%;
					@include translate(0, -50%);
					left: 0;
					height: 1px;
					width: 35px;
					background: $borderColor;
				}
				&:before {
				}
			}

			> li {
				&:after {
					content: '';
					position: absolute;
					top: 50%;
					@include translate(0, -50%);
					left: 35px;
					width: 12px;
					height: 12px;
					@include border-radius(50%);
				}

				&:before, &:after {
					background: $primary;
				}
			}
		}
	}

	@media (max-width: 767px) {
		.col-sm-6 + .col-sm-6 {
			margin-top: 30px;
		}
	}

	@media (max-width: 479px) {
		.col-xs-6 {
			width: 100%;
			float: none;
		}
		.col-xs-6 + .col-xs-6 {
			margin-top: 30px;
		}
	}
}

/* ===================   Contact page   =================== */
.contact_page {
	.panel {
		padding-top: 15px;
		background-color: transparent;
		border: none;
		border-top: 2px solid $primary;
		@include box-shadow(none);
		img {
			background-color: transparent;
		}
	}
	.map {
		border-top: 2px solid $primary;
		margin-bottom: 30px;
	}
	.icon {
		position: relative;
		padding-left: 70px;
		min-height: 55px;
		display: block;
		&[class*="fa-"]:before {
			font-family: $fa;
		}
		&:before {
			position: absolute;
			left: 0;
			top: 0;
			width: 55px;
			height: 55px;
			line-height: 55px;
			text-align: center;
			color: $primary;
			@include border-radius(50%);
			border: 2px solid $primary;
			font-size: 26px;
		}
		+ .icon {
			margin-top: 20px;
		}
	}
	.col-sm-4:nth-child(3n-2) {
		clear: both;
	}
	.col-sm-4:nth-child(n+4) {
		margin-top: 15px;
	}
	.form-horizontal .form-group {
		margin-left: 0;
		margin-right: 0;
		label {
			font-size: 14px;
			text-transform: uppercase;
			margin-bottom: 15px;
		}
	}
	@media (max-width: 767px) {
		.col-sm-4:nth-child(n+2) {
			margin-top: 30px;
		}
	}
}

/* ===================   Manufacturer List  =================== */
.manufacturer-list {
	@include clearfix();
	.manufacturer-heading {
		font-weight: bold;
		color: $textPrimary;
		float: left;
		display: block;
		font-size: 25px;
		text-align: center;
		text-transform: uppercase;
		width: 15%;
		height: 50px;
		line-height: 50px;
		@include box-sizing(border-box);
	}
	.manufacturer-content {
		padding: 12px 15px;
		float: left;
		width: 85%;
		border-left: 1px solid $borderColor;
		@include box-sizing(border-box);
		a {
			white-space: nowrap;
		}
	}
	+ .manufacturer-list {
		border-top: 1px solid $borderColor;
	}
}

.brands {
	background: $stockBg;
	padding: 20px;
	margin-bottom: 30px;
}

/* ===================   Account Page  =================== */
.well {
	background-color: #fff;
	border: 1px solid $borderColor;
	border-top: 3px solid $secondary;
	margin-bottom: 30px;
	padding: 30px;
	border-radius: 0;
	box-shadow: none;
	.heading {
		margin-bottom: 20px;
		@include clearfix;
		i {
			color: $primary;
			float: left;
			font-size: 56px;
			margin-right: 14px;
			line-height: 60px;
		}
		h2 {
			font-size: 23px;
			color: $textPrimary;
			margin: 0 0 5px;
		}
		strong {
			font-weight: normal;
			font-style: italic;
		}
	}
	p {
		border-bottom: 1px solid $borderColor;
		padding: 20px 0;
	}
	a {
		display: inline-block;
		margin-top: 20px;
	}
}

.account-account {
	h3 {
		text-transform: uppercase;
		padding-bottom: 20px;
		border-bottom: 1px solid $primary;
		i {
			color: $primary;
			margin-right: 10px;
			font-size: 30px;
		}
	}
	@media (max-width: 767px) {
		.col-sm-4 + .col-sm-4 {
			margin-top: 30px;
		}
	}
}

.account-address, .account-address-delete {
	.table {
		width: 50%;
		margin: 0 auto;
	}
	@media (max-width: 991px) {
		.table {
			width: 100%;
		}
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.well .heading {
		text-align: center;
		h2 {
			font-size: 17px;
		}
		i {
			float: none;
		}
	}
}

.datepicker {
	.prev, .next, .day, .year, .month, .picker-switch {
		cursor: pointer;
		@include transition(.3s all ease);
		&:hover, &.active {
			color: $primary;
			background: transparent;
		}
	}
	th {
		text-align: center;
	}
}

/* fixed colum left + content + right*/
@media (min-width: 768px) {
	#column-left .product-layout .col-md-3 {
		width: 100%;
	}
	#column-left + #content .product-layout .col-md-3 {
		width: 50%;
	}
	#column-left + #content + #column-right .product-layout .col-md-3 {
		width: 100%;
	}
	#content + #column-right .product-layout .col-md-3 {
		width: 100%;
	}
}

input#input-voucher,
input#input-coupon {
	width: 90%;
}

#collapse-coupon,
#collapse-voucher {
	.col-sm-2 {
		width: 100%;
		float: none;
		padding-left: 3px;
	}
}

/*========================================================
                         Footer
=========================================================*/
//Footer
@import "sections/_footer.scss";
@import "components/widgets/_toTop.scss";

/*========================================================
                  Responsive Styles
=========================================================*/

@media (max-width: 1199px) {
	.table th, .table td {
		padding: 8px;
	}
	.advantages .col-sm-3 {
		padding: 0;
	}
}

@media (min-width: 1200px) {
	.container {
		width: 1200px;
	}
}

@media (max-width: 767px) {
	select {
		display: block !important;
	}
	.product-filter {
		select {
			display: none !important;
			border-left-color: #f1f1f1;
			border-right-color: #f1f1f1;
		}
		.show select {
			width: auto;
		}
	}
	.sbHolder {
	}
	.table-responsive {
		border: none;
		.table-bordered {
			border: 2px solid #ccc;
		}
	}
	.delivery_page .table {
		font-size: 13px;
	}
	.product-filter {
		.sort {
			width: auto;
			padding-left: 10px;
			.sbHolder, select {
				width: 200px;
			}
			label {
				color: #777;
			}
		}
	}
	.image {
		text-align: center;
	}
	.success {
		padding: 45px 20px;
		width: 300px;
		margin-left: -150px;
		.success_button {
			padding: 6px 5px;
			font-size: 13px;
		}
	}
}

@media (max-width: 600px) {
	.product-filter {
		background: #fafafa;
		width: 75%;
		margin-left: auto;
		margin-right: auto;
		.sort {
			width: 100%;
			padding-left: 0;
			margin-bottom: 15px;
		}
		&_elem, &_elem.pull-right {
			float: none !important;
		}
		.product-filter_elem {
			@include clearfix;
			margin-bottom: 15px;
			text-align: center;
			label {
				margin-top: 5px;
				float: left;
				text-align: right;
				width: 35%;
				margin-right: 0;
				@include box-sizing(border-box);
				padding-right: 15px;
			}
			select {
				width: 65%;
				float: left;
			}
		}
		&_elem.show select {
			border-right: 1px solid $borderColor;
		}
		.compare-total {
			border-right: none;
			width: auto;
			&:before {
				display: none;
			}
			span {
				display: inline-block;
			}
		}
	}
}

@media (max-width: 580px) {
	.panel-group .panel {
		.input-group,
		.input-group-btn,
		input#input-voucher,
		input#input-coupon,
		input#button-voucher,
		input#button-coupon {
			width: 100%;
		}
		.input-group-btn {
			float: left;
			margin-top: 5px;
		}
	}
	h1 {
		font-size: 18px;
		line-height: 30px;
	}
}

@media (max-width: 550px) {
	.product-filter {
		width: 100%;
		padding: 0;
		margin: 20px 35px;
		.product-filter_elem select {
			border: 0 !important;
		}
	}
}

@media (max-width: 479px) {
	#habla_beta_container_do_not_rely_on_div_classes_or_names {
		display: none !important;
	}
	.common-home {
		#fullpage {
			.section {
				.block {
					.mobheader {
						text-align: left;
					}
				}
			}
		}
	}
}

@media (max-width: 410px) {
	#accordion .table td,
	#accordion .table thead td {
		font-size: 9px;
		word-wrap: break-word
	}
}

@media (max-width: 370px) {
	.delivery_page .table td,
	.delivery_page .table th {
		padding: 6px
	}
}

#habla_window_div.olrk-fixed-bottom .habla_panel_border,
#habla_window_div.olrk-fixed-bottom #habla_topbar_div {
	border-radius: 15px 15px 0 0 !important;
	border: 1px solid #fff !important;
}

.bootstrap-datetimepicker-widget.dropdown-menu.picker-open {
	min-width: 100px;
	text-align: center;
	padding: 0;
}

.bootstrap-datetimepicker-widget .timepicker td span.timepicker-hour,
.bootstrap-datetimepicker-widget .timepicker td span.timepicker-minute {
	font-size: 20px;
	padding: 0 3px;
}

.bootstrap-datetimepicker-widget a.btn:hover {
	background: none !important;
}

#accordion {
	.panel-body {
		padding: 25px 0;
		.checkout-checkout &,
		.checkout-cart & {
			padding: 15px;
		}
	}
}

.panel-body {
	padding: 25px 15px 15px;
	.box {
		margin-bottom: 0;
		.product-layout {
			&:last-child {
				margin-bottom: 0;
				#content & {
					margin-bottom: 0;
				}
			}
		}
	}
}

.panel-title {
	text-transform: uppercase;
}

.panel {
	border-radius: 8px !important;
}

.panel-heading {
	@include border-radius(8px);
}

.panel-group .panel + .panel {
	margin-top: 10px;
}

@media (min-width: 768px) and (max-width: 991px) {
	[data-clear-sm] + .product-layout {
		clear: left;
	}
}

@media (max-width: 321px) {
	.iphone #fullpage {
		.fp-noscroll {
			.imgblock {
				height: 29vh !important;
				a {
					height: 20vh !important;
					img {
						height: 40vw !important;
					}
				}
				h2 {
					width: 38vw !important;
				}
			}
		}
	}
}

@media (min-width: 992px) {
	[data-clear-md] + .product-layout {
		clear: left;
	}
}

.main-title {
	position: absolute;
	left: -9999rem;
	top: -9999rem;
}

.cat_desc {
	padding: 15px;
	background: #fff;
	margin-top: 15px;
}